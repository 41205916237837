import React, { useState, useEffect } from 'react'
import {
  Paper,
  Typography,
  Button,
  Grid,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import MSettingTable from 'components/MSettingTable'
import MainLayout from 'layout/MainLayout'
import MKeyDialog from 'components/MKeyDialog'

import api from 'utils/api'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(3),
    },
    formControl: {
      display: 'flex',
      minWidth: 200,
      maxWidth: 200,
      marginTop: theme.spacing(2),
    },
    subTitle: {
      marginTop: theme.spacing(3),
    },
    backDrop: {
      zIndex: 9999,
    },
  }),
)

const columns = [
  { field: 'name', headerName: 'Name' },
  { field: 'key', headerName: 'Key' },
  { field: 'status', headerName: 'Status' },
  { field: 'ga4_pass', headerName: 'GA4 Passthrough' },
  { field: 'createdAt', headerName: 'Date Created' },
  { field: 'updatedAt', headerName: 'Last Event' },
]

const ManageKeys = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const [rows, setRows] = useState<any[]>([])
  const [currentEditIndex, setCurrentEditIndex] = useState<any>(-1)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const findRowIndex = (id) => {
    return rows.findIndex((row) => row.id === id)
  }

  const onEdit = (id) => {
    setCurrentEditIndex(findRowIndex(id))
    setOpenDialog(true)
  }

  const onArchive = () => {}

  const onOpenAddKeyDialog = () => {
    setOpenDialog(true)
  }

  const onConfirmDialog = async (values, id) => {
    setLoading(true)

    try {
      const postUrl =
        id === -1 ? '/server-events/keys/add' : '/server-events/keys/update'

      const res = await api.get(postUrl, { ...values, id: id })
      setRows(res)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const fetch = async () => {
    setLoading(true)

    try {
      const res = await api.get('/server-events/keys')
      setRows(res)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  return (
    <MainLayout title='Manage Keys Page'>
      <>
        <Paper className={classes.paper}>
          <Grid container style={{ marginBottom: '20px' }}>
            <Grid item>
              <Typography variant='h6'>Integrations</Typography>
            </Grid>
            <Grid item style={{ marginLeft: 'auto' }}>
              <Button
                color='primary'
                variant='contained'
                onClick={onOpenAddKeyDialog}
              >
                New Key
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <MSettingTable
              rows={rows}
              columns={columns}
              handleProps={[
                { text: 'Edit', handler: onEdit },
                { text: 'Archive', handler: onArchive },
              ]}
            />
          </Grid>
        </Paper>

        <MKeyDialog
          open={openDialog}
          handleConfirmDialog={onConfirmDialog}
          setDialogOpen={setOpenDialog}
          editProps={currentEditIndex !== -1 ? rows[currentEditIndex] : null}
        />

        <Backdrop open={loading} className={classes.backDrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </>
    </MainLayout>
  )
}

export default ManageKeys
