import React, {useState, useEffect} from 'react'
import { Form, FormSpy, Field } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import { IconButton, Paper, Typography } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import api from 'utils/api'
import MainLayout from 'layout/MainLayout'
import MSelect from 'components/forms/MSelect'
import MCheckbox from 'components/forms/MCheckbox'
import MEdit from 'components/forms/MEdit'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(3),
      height: 'calc(100vh - 160px)',
    },
    settingContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      columnGap: '50px',
    },
    formControl: {
      display: 'flex',
      marginTop: theme.spacing(2),
    },
    subTitle: {
      marginTop: theme.spacing(3),
    },
    platformTitle: {
      marginLeft: theme.spacing(3),
    },
    checkbox: {

    },
    backDrop: {
      zIndex: 9999,
    },
  }),
)

type Props = {
  project?: string
}

const ProjectPage:React.FC<Props> = ({project}) => {
  const classes = useStyles()

  const [projects, setProjects] = useState<any[]>([])
  const [accounts, setAccounts] = useState<any>({})
  const [projectAccounts, setProjectAccounts] = useState<any[]>([])
  const [anchorEl, setAnchorEl] = useState<any[]>([])
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [curIndex, setCurIndex] = useState<number>(-1)
  const [datasets, setDatasets] = useState<any[]>([])

  const getDatasets = async() => {
    setLoading(true)

    try {
      const res = await api.get('/datasets')
      setDatasets(res)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const setAnchor = (index, target) => {
    let temp:any = []
    anchorEl.forEach((item, ind) => {
      if (index === ind) {
        temp.push(target)
      } else {
        temp.push(item)
      }
    })
    setAnchorEl(temp)
  }

  //click the menu button
  const handleClick = (event, index) => {
    setAnchor(index, event.currentTarget)
  }

  //close the menu
  const handleClose = (index) => {
    setAnchor(index, null)
  }

  //click delete on the menu
  const handleDelete = (index) => {
    setAnchor(index, null)
    setConfirmOpen(true)
    setCurIndex(index)
  }

  //click edit on the menu
  const handleEdit = (index) => {
    setAnchor(index, null)
    setCurIndex(index)
    getDatasets()
    setEditOpen(true)
  }

  //click ok on the delete dialog
  const handleDeleteProject = () => {
    deleteProject(projects[curIndex])
    setConfirmOpen(false)
  }

  const handleNewProject = () => {
    handleEdit(-1)
  }

  //click cancel on the delete dialog
  const handleConfirmClose = () => {
    setConfirmOpen(false)
  }

  //click close on the edit dialog
  const handleEditClose = () => {
    setEditOpen(false)
  }


  const saveData = (values: any) => {
    if (curIndex === -1) {
      addProject(values)
    } else {
      updateProject(values)
    }
    setEditOpen(false)
  }

  const onChange = (props: any) => {}

  const addProject = async(values: any) => {
    setLoading(true)
    try {
      const rows = await api.get('/projects/add', values)

      let projectId = -1
      let temp:any[] = []
      rows.forEach(row => {
        temp.push(null) 
        projectId = Math.max(projectId, row.id)
      })

      const checkRows = await api.get('/project_accounts/update', {...values, id: projectId})

      setLoading(false)
      setProjects(rows)
      setProjectAccounts(checkRows)
      setAnchorEl(temp)
    } catch {
      setLoading(false)
    }
  }

  const updateProject = async(values: any) => {
    setLoading(true)
    try {
      const rows = await api.get('/projects/update', values)
      const checkRows = await api.get('/project_accounts/update', values)

      setProjects(rows)
      setProjectAccounts(checkRows)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const deleteProject = async(values: any) => {
    setLoading(true)
    try {
      const rows = await api.get('/projects/delete', values)

      setProjects(rows)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const fetchProjectSettings = async() => {
    setLoading(true)
    try {
      const rows = await api.get('/projects')
      const accountRows = await api.get('/accounts')
      const checkRows = await api.get('/project_accounts')

      let temp:any[] = []
      rows.forEach(row => temp.push(null))

      setLoading(false)
      setAccounts(accountRows)
      setProjects(rows)
      setProjectAccounts(checkRows)
      setAnchorEl(temp)
    } catch {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProjectSettings()
  }, []) //eslint-disable-line 

  return (
    <MainLayout title='Project Settings Page'>
      <>
      <Paper className={classes.paper} elevation={3}>
        <Grid container style={{marginBottom: '20px'}}>
          <Grid item>
            <Typography variant='h6'>Projects</Typography>
          </Grid>
          <Grid item style={{marginLeft: 'auto'}}>
            <Button color='primary' variant='contained' onClick={handleNewProject}> New Project </Button>
          </Grid>
        </Grid>
        {
          projects?.map((project, index) => {
            return (
              <div key={index} 
                  style={{
                    display:'flex', 
                    justifyContent:'space-between', 
                    borderBottom:'3px solid #ddd',
                    alignItems: 'center',
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    height: '60px',
                  }}
              >
                <span style={{cursor: 'pointer'}} onClick={() => handleEdit(index)}>{project.text}</span>
                <div>
                  <IconButton onClick={(event) => handleClick(event, index)}>
                    <MoreVert/>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl[index]}
                    open={Boolean(anchorEl[index])}
                    onClose={() => handleClose(index)}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        width: '15ch',
                      },
                    }}
                  >
                    <MenuItem onClick={() => handleEdit(index)} dense={true}>Edit</MenuItem>
                    <MenuItem onClick={() => handleDelete(index)} dense={true}>Delete</MenuItem>
                  </Menu>
                </div>
              </div>
            )
          })
        }
      </Paper>

      <Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Project"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to permanently delete this project?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteProject} color="primary">
            OK
          </Button>
          <Button onClick={handleConfirmClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={editOpen}
        onClose={handleEditClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Project Details</DialogTitle>
        <DialogContent>
          <Form
          onSubmit={saveData}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormSpy subscription={{ values: true }} onChange={onChange} />
              <div style={{display:'none'}}>
                <Field
                className={classes.formControl}
                name='id'
                defaultValue={ curIndex === -1 ? '' : projects[curIndex].id}
                render={(props) => (
                  <MEdit {...props} />
                )}
                />
              </div>

              <div className={classes.settingContainer}>
                <div>
                  <Field
                    className={classes.formControl}
                    name='text'
                    label='Project Name'
                    defaultValue={ curIndex === -1 ? '' : projects[curIndex].text}
                    render={(props) => (
                      <MEdit {...props} />
                    )}
                  />

                  <Field
                    className={classes.formControl}
                    name='vertical'
                    label='Vertical'
                    defaultValue='Medicare'
                    variantType='standard'
                    render={(props) => (
                      <MSelect {...props} data={['Medicare', 'Insurance', 'Other']} />
                    )}
                  />

                  <Field
                    className={classes.formControl}
                    name='db'
                    label='Big Query DB'
                    defaultValue={ curIndex === -1 ? '' : projects[curIndex].db}
                    variantType='standard'
                    render={(props) => (
                      <MSelect {...props} data={datasets} />
                    )}
                  />

                  <Typography variant='h6' component="h5" className={classes.subTitle}>
                    GA4 Offline Events(optional)
                  </Typography>

                  <Field
                    className={classes.formControl}
                    name='gtm_server_host'
                    label='GTM Server Host'
                    render={(props) => (
                      <MEdit {...props} />
                    )}
                  />

                  <Field
                    className={classes.formControl}
                    name='ga4_measurement_id'
                    label='GA4 Measurement ID'
                    render={(props) => (
                      <MEdit {...props} />
                    )}
                  />

                  <Field
                    className={classes.formControl}
                    name='api_secret'
                    label='API Secret'
                    render={(props) => (
                      <MEdit {...props} />
                    )}
                  />  
                </div>

                <div>
                  <Typography variant='h6' component="h5" className={classes.subTitle}>
                      Associated Ad Accounts
                  </Typography>
                  <div>
                  {
                    (Object.keys(accounts)).map(key => {
                      return (
                        <>
                          <Typography variant='h6' component="h6" className={classes.platformTitle}>
                              {key}
                          </Typography>
                          {
                            accounts[key].map(account => {
                              let checked = false
                              let projectId = curIndex === -1 ? 0 : projects[curIndex].id
                              projectAccounts[projectId]?.forEach(item => {
                                if (account.id === item)
                                  checked = true
                              })
                              return (
                                <Field 
                                  className={classes.checkbox} 
                                  name={`project_account.${projectId}_${account.id}`}
                                  label={`${account.name}(${account.id})`} 
                                  type='checkbox'
                                  render={(props) => (
                                    <MCheckbox {...props} defaultChecked={checked || false}/>
                                  )}
                                />
                            )
                            })
                          }
                        </>
                      )
                    })
                  }
                  </div>
                </div>
              </div>
              <DialogActions>
                <Button onClick={()=>saveData(values)} color="primary">
                  { curIndex === -1 ? 'Add' : 'Save'}
                </Button>
                <Button onClick={handleEditClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </form>
          )}
        />
        </DialogContent>
      </Dialog>
      <Backdrop open={loading} className={classes.backDrop}>
        <CircularProgress color='inherit' />
      </Backdrop>
      </>
    </MainLayout>
  )
}

export default ProjectPage