import { combineReducers } from 'redux'
import app from './app/reducer'
import auth from './auth/reducer'
import project from './project/reducer'

export default function rootReducer() {
  return combineReducers({
    app,
    auth,
    project,
  })
}
