import SourcesPage from './Sources'
import { SnackbarProvider } from 'notistack';

const SourcePage = () => {
  return (
    <SnackbarProvider maxSnack={1}>
      <SourcesPage />
    </SnackbarProvider>
  )
}

export default SourcePage
