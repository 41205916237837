import React, { useState, useEffect } from 'react'
import { Form, FormSpy, Field } from 'react-final-form'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { Add, Remove } from '@material-ui/icons'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import MEdit from 'components/forms/MEdit'
import MSelect from 'components/forms/MSelect'

import api from 'utils/api'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      display: 'flex',
      minWidth: '100%',
      maxWidth: '100%',
      marginBottom: theme.spacing(5),
    },
  })
)

type Props = {
  open: boolean
  setOpen: any
  confirm: any
  defaultValue?: any
}

const propertyList = [
  {
    id: 'event_name',
    text: 'Event Name',
  },
  {
    id: 'domain',
    text: 'Domain',
  },
  {
    id: 'sale_revenue',
    text: 'Revenue',
  },
  {
    id: 'sale_type',
    text: 'Sale Type',
  },
  {
    id: 'sale_tier',
    text: 'Sale Tier',
  },
  {
    id: 'buyer',
    text: 'Buyer',
  },
  {
    id: 'sub_buyer',
    text: 'Sub Buyer',
  },
]

const conditionList: any[] = [
  {
    id: 'contains',
    text: 'contains',
  },
  {
    id: '==',
    text: 'equals',
  },
  {
    id: 'starts with',
    text: 'starts with',
  },
  {
    id: 'ends with',
    text: 'ends with',
  },
  {
    id: '!=',
    text: 'does not equal',
  },
  {
    id: 'does not contain',
    text: 'does not contain',
  },
  {
    id: '<',
    text: 'less than',
  },
  {
    id: '<=',
    text: 'less than or equal to',
  },
  {
    id: '>',
    text: 'greater than',
  },
  {
    id: '>=',
    text: 'greater than or equal to',
  },
]

let eventKeyList = []

const MPixelsDialog: React.FC<Props> = ({
  open,
  setOpen,
  confirm,
  defaultValue,
}) => {
  const classes = useStyles()

  const [filters, setFilters] = useState<any[]>([
    {
      combination: 'and',
      property: propertyList[0],
      condition: conditionList[0],
      value: '',
    },
  ])

  const handleEditClose = () => {
    setOpen(false)
  }

  const handleSave = async (values) => {
    let submitValues = {
      name: values.name,
      status: values.status,
      key: values.key,
      method: values.method,
      url: values.url,
      event_filters: [],
    }
    for (let i = 0; i < filters.length; i++) {
      submitValues.event_filters.push({
        combination: filters[i].combination,
        property: values[`property_${i}`],
        condition: values[`condition_${i}`],
        value: values[`value_${i}`],
      })
    }

    console.log(submitValues)
    await confirm(submitValues, defaultValue)
    setOpen(false)
  }

  const handleCombinationChange = (
    index: number,
    event: React.MouseEvent<HTMLElement>,
    newVal: any | null
  ) => {
    const temp = filters.map((filter, id) => {
      if (id === index) {
        return { ...filter, combination: newVal }
      } else {
        return filter
      }
    })

    setFilters(temp)
  }

  const onChange = (props: any) => {}

  const addFilter = () => {
    setFilters((prevFilters) => [
      ...prevFilters,
      {
        combination: 'and',
        property: propertyList[0],
        condition: conditionList[0],
        value: '',
      },
    ])
  }

  const removeFilter = (index: number) => {
    if (filters.length === 1) {
      return
    }
    const temp = filters.map((item) => item)
    temp.splice(index, 1)
    setFilters(temp)
  }

  useEffect(() => {
    if (open) {
      if (Object.keys(defaultValue).length === 0) {
        setFilters([
          {
            combination: 'and',
            property: propertyList[0],
            condition: conditionList[0],
            value: '',
          },
        ])
      } else {
        const tempFilters = defaultValue.event_filters.map(item => {
          const property = propertyList.find(row => row.id === item.property)
          const condition = conditionList.find(row => row.id === item.condition)
          return {
            ...item,
            property: property,
            condition: condition,
          }
        })
        setFilters(tempFilters)
      }
    }
  }, [open])

  const fetchKeys = async () => {
    const res = await api.get('/server-events/keys')
    res.forEach((item) => {
      eventKeyList.push({ text: item.name, id: item.key })
    })
  }

  useEffect(() => {
    fetchKeys()
  }, [])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={open}
      onClose={handleEditClose}
      aria-labelledby='max-width-dialog-title'
    >
      <DialogTitle id='max-width-dialog-title'>Pixel</DialogTitle>
      <DialogContent>
        <Form
          initialValues={defaultValue}
          onSubmit={confirm}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormSpy subscription={{ values: true }} onChange={onChange} />
              <Field
                className={classes.formControl}
                name='name'
                label='Name'
                render={(props) => <MEdit {...props} />}
              />

              <Field
                className={classes.formControl}
                name='status'
                label='Status'
                variantType='standard'
                defaultValue={defaultValue.status || 'Active'}
                render={(props) => (
                  <MSelect {...props} data={['Active', 'Testing']} />
                )}
              />

              <Field
                className={classes.formControl}
                name='key'
                label='Key Name'
                variantType='standard'
                defaultValue={defaultValue.key || eventKeyList[0]}
                render={(props) => <MSelect {...props} data={eventKeyList} />}
              />

              <Typography>Event Filter</Typography>

              {filters.map((filter, index) => {
                return (
                  <>
                    {index !== 0 && (
                      <Grid>
                        <ToggleButtonGroup
                          exclusive
                          value={filters[index].combination}
                          onChange={(
                            event: React.MouseEvent<HTMLElement>,
                            newVal: any | null
                          ) => handleCombinationChange(index, event, newVal)}
                        >
                          <ToggleButton value='and'>AND</ToggleButton>
                          <ToggleButton value='or'>OR</ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                    )}

                    <Grid
                      key={`filter${index}`}
                      container
                      spacing={2}
                      style={{ marginTop: '15px' }}
                    >
                      <Grid item xs={4} style={{ marginLeft: 'auto' }}>
                        <Field
                          className={classes.formControl}
                          name={`property_${index}`}
                          label='Property'
                          defaultValue={filters[index].property.id}
                          variantType='standard'
                          render={(props) => (
                            <MSelect {...props} data={propertyList} />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          className={classes.formControl}
                          name={`condition_${index}`}
                          label='Condition'
                          defaultValue={filters[index].condition.id}
                          variantType='standard'
                          render={(props) => (
                            <MSelect {...props} data={conditionList} />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          className={classes.formControl}
                          name={`value_${index}`}
                          label='Value'
                          defaultValue={filters[index].value}
                          render={(props) => <MEdit {...props} />}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton onClick={addFilter}>
                          <Add />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => removeFilter(index)}>
                          <Remove />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                )
              })}

              <Grid container spacing={2} style={{ marginTop: '30px' }}>
                <Grid item xs={2}>
                  <Field
                    className={classes.formControl}
                    name='method'
                    label='Method'
                    variantType='standard'
                    defaultValue={defaultValue.method || 'GET'}
                    render={(props) => (
                      <MSelect {...props} data={['GET', 'POST']} />
                    )}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Field
                    className={classes.formControl}
                    name='url'
                    label='URL'
                    render={(props) => <MEdit {...props} />}
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button onClick={() => handleSave(values)} color='primary'>
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}

export default MPixelsDialog
