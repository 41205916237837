import { useState, useEffect } from 'react'
import MainLayout from 'layout/MainLayout'
import { Form, FormSpy, Field } from 'react-final-form'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import RefreshIcon from '@material-ui/icons/Refresh'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import cookie from 'react-cookies'

import api from 'utils/api'
import MSortTable from 'components/MSortTable/MSortTable'
import MDateRangePicker from 'components/forms/MDateRangePicker'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      minWidth: 110,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button: {
      height: 50,
      verticalAlign: 'middle',
    },
    table: {
      maxHeight: 'calc(100vh - 200px)',
      minHeight: 'calc(100vh - 200px)',
    },
    subTable: {
      maxHeight: '300px',
      minHeight: '300px',
    },
    backDrop: {
      zIndex: 5,
    },
  }),
)

const columns: TableColumnDef[] = [
  { field: 'page_location', headerName: 'Landing Page', width: '600' },
  { field: 'views', headerName: 'Views', align: 'center', width: '80' },
  { field: 'clicks', headerName: 'Clicks', align: 'center', width: '80' },
  { field: 'ctr', headerName: 'CTR', align: 'center', width: '80' },
  {
    field: 'cpc_clicks',
    headerName: 'CPC Clicks',
    align: 'center',
    width: '80',
  },
  { field: 'leads', headerName: 'Leads', align: 'center', width: '80' },
  { field: 'conv_rate', headerName: 'CR', align: 'center', width: '80' },
]

const landingPageClickColumns: TableColumnDef[] = [
  { field: 'link_type', headerName: 'Link Type', width: '600' },
  { field: 'count', headerName: 'Amount Selected', align: 'center', width: '80' },
  { field: 'percent', headerName: '% of Total', align: 'center', width: '80' },
]

let defaultFilter = {
  dateRange: {
    startDate: new Date(),
    endDate: new Date()
  }
}
const LandingDetailPage = () => {
  const classes = useStyles()
  const [state, setState] = useState<{
    loading: boolean
    row: any
    sources: any[]
    devices: any[]
    publishers: any[]
    landingPageClicks: any[]
    filter?: any
  }>({
    loading: true,
    row: {},
    sources: [],
    devices: [],
    publishers: [],
    landingPageClicks: [],
    filter: [],
  })

  const fetchData = async (params: any) => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }))

    try {
      const cookieData = cookie.load('landingInfo')

      const sources = await api.get('/landing/sources', {
        ...params,
        ...params.dateRange,
        project: cookieData.project,
        pageUrl: cookieData.page_location,
        detailType: 'source',
        detailColumnField: 'source',
        dateRange: undefined,
      })

      const devices = await api.get('/landing/sources', {
        ...params,
        ...params.dateRange,
        project: cookieData.project,
        pageUrl: cookieData.page_location,
        detailType: 'device.category',
        detailColumnField: 'device',
        dateRange: undefined,
      })

      const publishers = await api.get('/landing/sources', {
        ...params,
        ...params.dateRange,
        project: cookieData.project,
        pageUrl: cookieData.page_location,
        detailType: 'pub_token',
        detailColumnField: 'pub_token',
        dateRange: undefined,
      })

      const landingPageClicks = await api.get('/landing/linkValues', {
        ...params,
        ...params.dateRange,
        project: cookieData.project,
        pageUrl: cookieData.page_location,
        dateRange: undefined,
      })

      setState((prev) => ({
        ...prev,
        loading: false,
        sources: sources,
        devices: devices,
        publishers: publishers,
        landingPageClicks: landingPageClicks,
        row: { ...cookieData },
        filter: params,
      }))
    } catch {
      setState((prev) => ({
        ...prev,
        loading: false,
      }))
    }
  }

  const onChange = (props: any) => {
    fetchData(props.values)
  }

  useEffect(() => {
    const dateRange = cookie.load('landing-dateRange')
    defaultFilter = { dateRange: { startDate:dateRange.startDate, endDate:dateRange.endDate} }
    fetchData(defaultFilter)
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  let sourceColumns: any[] = []
  let deviceColumns: any[] = []
  let publisherColumns: any[] = []
  columns.forEach((column) => {
    sourceColumns.push(Object.assign({}, column))
    deviceColumns.push(Object.assign({}, column))
    publisherColumns.push(Object.assign({}, column))
  })
  sourceColumns[0].field = 'source'
  sourceColumns[0].headerName = 'Source'
  deviceColumns[0].field = 'device'
  deviceColumns[0].headerName = 'Device'
  publisherColumns[0].field = 'pub_token'
  publisherColumns[0].headerName = 'Publisher Tokens'

  return (
    <MainLayout title='Landing Details Page'>
      <>
        <Form
          initialValues={defaultFilter}
          onSubmit={fetchData}
          render={({ handleSubmit, values }) => (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <FormSpy subscription={{ values: true }} onChange={onChange} />
              <Grid container alignItems='center' spacing={2}>
                <Grid item style={{ marginLeft: 'auto' }}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='secondary'
                    className={classes.button}
                    startIcon={<RefreshIcon />}
                  >
                    Refresh Data
                  </Button>
                </Grid>
                <Grid item>
                  <Field
                    label='Date Range'
                    name='dateRange'
                    render={(props) => <MDateRangePicker {...props} />}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        />

        <MSortTable
          columns={columns}
          rows={[state.row]}
          paginate={false}
        />

        <Grid container spacing={2}>
          <Grid item md={6} xs={6} lg={6} style={{marginTop: '10px'}}>
            <Typography variant='h6'>Sources</Typography>
            <MSortTable
              className={classes.subTable}
              rows={state.sources}
              columns={sourceColumns}
              stickyHeader={true}
              paginate={false}
            />
          </Grid>
          <Grid item md={6} xs={6} lg={6} style={{marginTop: '10px'}}>
            <Typography variant='h6'>Devices</Typography>
            <MSortTable
              className={classes.subTable}
              rows={state.devices}
              columns={deviceColumns}
              paginate={false}
            />
          </Grid>
          <Grid item md={6} xs={6} lg={6} style={{marginTop: '10px'}}>
            <Typography variant='h6'>Publisher</Typography>
            <MSortTable
              className={classes.subTable}
              rows={state.publishers}
              columns={publisherColumns}
              paginate={false}
            />
          </Grid>
          <Grid item md={6} xs={6} lg={6} style={{marginTop: '10px'}}>
            <Typography variant='h6'>Landing Page Clicks</Typography>
            <MSortTable
              className={classes.subTable}
              rows={state.landingPageClicks}
              columns={landingPageClickColumns}
              paginate={false}
            />
          </Grid>
        </Grid>
        <Backdrop open={state.loading} className={classes.backDrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </>
    </MainLayout>
  )
}

export default LandingDetailPage
