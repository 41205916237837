import React from 'react'
import { TextField } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'

type Props = {
  label?: string
  previousData?: any
  input?: any
  value?: string
  size?: any
  variantType?: any
  multiline?: boolean
}

const MSelect: React.FC<
  Props & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  className,
  input,
  label,
  previousData,
  size = 'medium',
  value,
  variantType = 'outlined',
  multiline = false,
}) => {
  return (
    <FormControl
      variant={variantType ? variantType : 'outlined'}
      className={className}
      size={size}
    >
      <TextField {...input} label={label} InputLabelProps={{ shrink: true }} multiline={multiline} rows={5}/>
    </FormControl>
  )
}

export default MSelect
