import React from 'react'
import { Form, FormSpy, Field } from 'react-final-form'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import MSelect from './forms/MSelect'
import MEdit from 'components/forms/MEdit'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      display: 'flex',
      minWidth: '100%',
      maxWidth: '100%',
      marginBottom: theme.spacing(5),
    },
  }),
)

type Props = {
  open: boolean
  handleConfirmDialog: any
  setDialogOpen: any
  editProps?: any
}

const MKeyDialog: React.FC<Props> = ({
  open,
  handleConfirmDialog,
  setDialogOpen,
  editProps,
}) => {
  const classes = useStyles()

  const onDlgClose = () => {
    setDialogOpen(false)
  }

  const onDlgAccept = async (values) => {
    await handleConfirmDialog(values, editProps?.id || -1)
    onDlgClose()
  }

  const onChange = (values) => {}  
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={onDlgClose}
      aria-labelledby='max-width-dialog-title'
    >
      <DialogTitle id='max-width-dialog-title'>Add Integrations</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={handleConfirmDialog}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormSpy subscription={{ values: true }} onChange={onChange} />

              <Field
                className={classes.formControl}
                name='name'
                label='Name'
                placeholder='Key Name'
                variantType='standard'
                defaultValue={editProps?.name || ''} 
                render={(props) => <MEdit {...props} />}
              />

              <Field
                className={classes.formControl}
                name='status'
                label='Status'
                variantType='standard'
                defaultValue={editProps?.status || 'Active'}
                render={(props) => (
                  <MSelect
                    {...props}
                    data={['Active', 'Testing']}
                  />
                )}
              />

              <Field
                className={classes.formControl}
                name='ga4_pass'
                label='GA4 Passthrough'
                variantType='standard'
                defaultValue={editProps?.ga4_pass || 'No'}
                render={(props) => (
                  <MSelect
                    {...props}
                    data={['No', 'Yes']}
                  />
                )}
              />

              <DialogActions>
                <Button onClick={() => onDlgAccept(values)} color='primary'>
                  Save
                </Button>
                <Button onClick={onDlgClose} color='primary'>
                  Close
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}

export default MKeyDialog
