import { useDispatch } from 'react-redux'
import { Form, Field } from 'react-final-form'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { login } from 'store/auth/actions'
import { ReactComponent as LogoIcon } from 'icons/logo.svg'
import './login.scoped.css'

const LoginPage = () => {
  // const router = useRouter()
  const dispatch = useDispatch()

  const onSubmit = (values: any) => {
    if (values.username === 'admin' && values.password === 'admin') {
      dispatch(login(values))
    } else {
      window.alert('Credential is incorrect')
    }
  }

  return (
    <Container component='main' maxWidth='xs'>
      <div
        id='main-back'
        style={{ background: `url('/images/loginBack.png') no-repeat` }}
      />

      <CssBaseline />
      <div className='paper'>
        <div>
          <LogoIcon />
          <Typography component='h1' variant='h5'>
            Optimize Rocket
          </Typography>
          <Typography variant='h6'>Welcome back!</Typography>
        </div>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate className='form'>
              <label>Email Address</label>
              <Field
                name='username'
                render={({ input, meta }) => (
                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    autoFocus
                    className='form-control'
                    placeholder='Enter your email'
                    {...input}
                  />
                )}
              />

              <label>Password</label>
              <Field
                name='password'
                render={({ input, meta }) => (
                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    placeholder='Enter your password'
                    className='form-control'
                    name='password'
                    type='password'
                    {...input}
                  />
                )}
              />

              <label id='forgot-pass'>
                Forgot your password? <a href='/'>Reset Password</a>
              </label>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
              >
                Log In
              </Button>
            </form>
          )}
        />
      </div>
    </Container>
  )
}

export default LoginPage
