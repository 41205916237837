import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Form, FormSpy, Field } from 'react-final-form'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import RefreshIcon from '@material-ui/icons/Refresh'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import cookie from 'react-cookies'

import api from 'utils/api'
import { deviceList } from 'data/options'
import MainLayout from 'layout/MainLayout'
import MSelect from 'components/forms/MSelect'
import MDateRangePicker from 'components/forms/MDateRangePicker'
import DetailStatistics from 'components/pages/forms/DetailStatistics'
import MSortTable from 'components/MSortTable/MSortTable'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 200,
    },
    smFormControl: {
      minWidth: 120,
    },
    select: {
      minWidth: 110,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button: {
      height: 50,
      verticalAlign: 'middle',
    },
    table: {
      maxHeight: 'calc(100vh - 200px)',
      minHeight: 'calc(100vh - 200px)',
    },
    backDrop: {
      zIndex: 5,
    },
  }),
)

const columns: TableColumnDef[] = [
  { field: 'index', headerName: '#', width: '20' },
  {
    field: 'step_title',
    headerName: 'Step Title',
    align: 'center',
    width: '120',
  },
  {
    field: 'impressions',
    headerName: 'Impressions',
    align: 'center',
    width: '100',
  },
  { field: 'clicks', headerName: 'Clicks', align: 'center', width: '100' },
  { field: 'ctr', headerName: 'CTR', align: 'center', width: '100' },
  {
    field: 'avg_time',
    headerName: 'Avg. Time on Step',
    align: 'center',
    width: '100',
  },
  {
    field: 'val_errors',
    headerName: 'Validation Errors',
    align: 'center',
    width: '100',
  },
  {
    field: 'back_actions',
    headerName: 'Back Actions',
    align: 'center',
    width: '100',
  },
  {
    field: 'bounce_rate',
    headerName: 'Overall Bounce Rate',
    align: 'center',
    width: '100',
  },
  {
    field: 'conv_rate',
    headerName: 'Conversion Rate',
    align: 'center',
    width: 'auto',
  },
]

let defaultFilter = {
  dimension: 'All',
  device: 'All',
  formVersion: undefined,
  form_flow: undefined,
  dateRange: {
    startDate: new Date(),
    endDate: new Date(),
  },
}

type Props = {
  project?: string
}
const FormsDetailPage: React.FC<Props> = ({ project }) => {
  const classes = useStyles()
  const [state, setState] = useState<{
    loading: boolean
    rows: any[]
    filter?: any
    sourceList: any[]
  }>({
    loading: true,
    rows: [],
    sourceList: [],
    filter: defaultFilter,
  })

  const fetchData = async (params: any) => {
    console.log(params)
    if (!params.formVersion || !params.form_flow)
      return
      
    setState((prev) => ({
      ...prev,
      loading: true,
    }))

    try {
      const formInfo = cookie.load('formsInfo')

      const res = await api.get('/forms', {
        ...params,
        ...params.dateRange,
        page_location: formInfo.page_location,
        project: formInfo.project,
        detailTitle: undefined,
        dateRange: undefined,
      })
      // add some fields

      setState((prev) => ({
        ...prev,
        loading: false,
        rows: res,
        filter: params,
      }))
    } catch {
      setState((prev) => ({
        ...prev,
        loading: false,
      }))
    }
  }

  const fetchDetailData = async (params: any, selectRow: any) => {

    setState((prev) => ({
      ...prev,
      loading: true,
    }))

    try {
      const detailTitle = selectRow.step_title
      const res = await api.get('/forms', {
        ...params,
        ...params.dateRange,
        project: project,
        detailTitle,
        dateRange: undefined,
      })
      // add some fields
      const tempRows: any[] = []

      state.rows.forEach((row, row_index) => {
        tempRows[row_index] = { ...row }
        if (row.step_title === selectRow.step_title) {
          tempRows[row_index] = { ...row, detailRows: res }
        }
      })
      setState((prev) => ({
        ...prev,
        rows: tempRows,
        loading: false,
      }))
    } catch {
      setState((prev) => ({
        ...prev,
        loading: false,
      }))
    }
  }

  const getSourceList = async (params: any) => {
    setState({
      ...state,
      loading: true,
    })

    try {
      const res = await api.get('/forms/getSourceList', {
        ...params.dateRange,
        ...params,
        project: project,
        dateRange: undefined,
      })
      // add some fields
      setState((prev) => ({
        ...prev,
        sourceList: res,
        loading: false,
      }))
    } catch {
      setState((prev) => ({
        ...prev,
        loading: false,
      }))
    }
  }

  const onChange = (props: any) => {
    if (props.values.dateRange !== state.filter.dateRange)
      getSourceList(props.values)
    
    fetchData(props.values)
  }

  const onRowClick = (open: boolean, row: any) => {
    if (open) return
    return fetchDetailData(state.filter, row)
  }

  useEffect(() => {
    const dateRange = cookie.load('formsDateRange')
    const formInfo = cookie.load('formsInfo')
    defaultFilter = {
      ...defaultFilter, 
      dateRange: { startDate: dateRange.startDate, endDate: dateRange.endDate },
      form_flow: formInfo.form_flow,
      formVersion: formInfo.form_version,
    }
    fetchData(defaultFilter)
    getSourceList(defaultFilter)
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData(state.filter)
    getSourceList(state.filter)
  }, [project]) //eslint-disable-line react-hooks/exhaustive-deps

  // change header name
  // columns[0].headerName = dimensionList.find(
  //   (item) => item.id === state.filter?.dimension,
  // )?.text

  return (
    <MainLayout title='Forms Pages'>
      <>
        <Form
          initialValues={defaultFilter}
          onSubmit={fetchData}
          render={({ handleSubmit, values }) => (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <FormSpy subscription={{ values: true }} onChange={onChange} />
              <Grid container alignItems='center' spacing={2}>
                <Grid item>
                  <Field
                    name='form_flow'
                    label='Form Flow'
                    className={classes.smFormControl}
                    render={(props) => (
                      <MSelect
                        {...props}
                        data={['1', '2', '3', '4', '5', '6']}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Field
                    className={classes.formControl}
                    name='dimension'
                    label='Source'
                    render={(props) => (
                      <MSelect {...props} data={state.sourceList} />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Field
                    name='device'
                    label='Device'
                    className={classes.smFormControl}
                    render={(props) => <MSelect {...props} data={deviceList} />}
                  />
                </Grid>

                <Grid item style={{ marginLeft: 'auto' }}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='secondary'
                    className={classes.button}
                    startIcon={<RefreshIcon />}
                  >
                    Refresh Data
                  </Button>
                </Grid>
                <Grid item>
                  <Field
                    label='Date Range'
                    name='dateRange'
                    render={(props) => <MDateRangePicker {...props} />}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        />
        <MSortTable
          rows={state.rows}
          columns={columns}
          className={classes.table}
          detailComponent={DetailStatistics}
          expandTable={true}
          onExpand={onRowClick}
          paginate={false}
          showComprehensiveData={true}
          sort={false}
        />

        <Backdrop open={state.loading} className={classes.backDrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </>
    </MainLayout>
  )
}

const mapStateToProps = (state) => {
  return state.project
}
export default connect(mapStateToProps)(FormsDetailPage)
