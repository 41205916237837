import { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import { useDispatch, useSelector } from 'react-redux'
import { Form, FormSpy, Field } from 'react-final-form'
//import MaterialLink from '@material-ui/core/Link'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import SettingsIcon from '@material-ui/icons/Settings'

import { selectAuth } from 'store/auth/reducer'
import { logout } from 'store/auth/actions'
import { setProject } from 'store/project/actions'
import MSelect from 'components/forms/MSelect'
import { ReactComponent as LogoIcon } from 'icons/logo.svg'
import api from 'utils/api'

import './MainLayout.css'

type Props = {
  title: string
  children: React.ReactChild
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  projectSelect: {
    marginRight: theme.spacing(1),
    width: '200px',
  },
}))

const defaultProject = {
  project: 0,
}

const MainLayout: React.FC<Props> = ({ title, children }) => {
  //const router = useRouter()
  const auth = useSelector((state) => selectAuth(state))
  const dispatch = useDispatch()
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [projects, setProjects] = useState<any[]>()

  useEffect(() => {
    if (!auth) {
      window.location.replace('/login')
    }
  }, [auth])

  const handleLogout = () => {
    dispatch(logout())
  }

  const onChange = (props) => {
    cookie.save(
      'current-project',
      { project: props.values.project },
      { path: '/' },
    )
    if (projects) {
      dispatch(setProject(projects[props.values.project]))
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleProject = () => {
    window.location.replace('/project')
  }

  const handleServerEvents = () => {
    window.location.replace('/server-events')
  }

  const handleIntegrations = () => {
    window.location.replace('/integrations')
  }

  const handleSourcePairing = () => {
    window.location.replace('/source-pairing')
  }

  const handlePixels = () => {
    window.location.replace('/pixels')
  }

  const fetchProjectSettings = async () => {
    try {
      const rows = await api.get('/projects')
      const temp = rows.map((row, index) => {
        return { ...row, id: index }
      })
      setProjects(temp)
    } catch {}
  }

  useEffect(() => {
    fetchProjectSettings()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (projects) {
      const curProject = cookie.load('current-project')
        ? cookie.load('current-project')
        : defaultProject
      dispatch(setProject(projects[curProject.project]))
    }
  }, [projects]) //eslint-disable-line react-hooks/exhaustive-deps

  if (!auth) {
    return null
  }

  const curProject = cookie.load('current-project')
    ? cookie.load('current-project')
    : defaultProject
  return (
    <>
      {/* <Head>
        <title>Optimize Rocket | {title}</title>
        <meta name='description' content='Optimize Rocket' />
        <link rel='icon' href='/favicon.ico' />
      </Head> */}
      <AppBar position='static' color='default' className={'appbar-navbar'}>
        <Toolbar>
          <Typography variant='h6'>
            <LogoIcon />
            <Typography className='title'>Optimize Rocket</Typography>
            <NavLink to='/' activeClassName='active-link' exact>
              Dimensions
            </NavLink>
            <NavLink to='/landing-page' activeClassName='active-link' exact>
              Landing Page
            </NavLink>
            <NavLink to='/forms' activeClassName='active-link'>
              Forms
            </NavLink>
            <NavLink to='/cpc-listings' activeClassName='active-link'>
              CPC Listings
            </NavLink>
            <NavLink to='/session-explorer' activeClassName='active-link'>
              Session Explorer
            </NavLink>
            <NavLink to='/ga4-events' activeClassName='active-link'>
              GA4 Events
            </NavLink>
          </Typography>

          <Form
            initialValues={curProject}
            onSubmit={() => {}}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <FormSpy subscription={{ values: true }} onChange={onChange} />
                <Field
                  name='project'
                  label='Project'
                  size='small'
                  render={(props) => (
                    <MSelect
                      {...props}
                      className={classes.projectSelect}
                      data={projects}
                      sort={false}
                    />
                  )}
                />
              </form>
            )}
          />
          <div>
            <IconButton
              aria-label='more'
              aria-controls='long-menu'
              aria-haspopup='true'
              onClick={handleClick}
            >
              <SettingsIcon />
            </IconButton>
            <Menu
              id='long-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 5.5,
                  width: '20ch',
                },
              }}
            >
              <MenuItem onClick={handleProject}>Project</MenuItem>
              <MenuItem onClick={handleServerEvents}>Server Events</MenuItem>
              <MenuItem onClick={handleIntegrations}>Integrations</MenuItem>
              <MenuItem onClick={handleSourcePairing}>Source Pairing</MenuItem>
              <MenuItem onClick={handlePixels}>Pixels</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Container style={{ maxWidth: 'unset' }}>{children}</Container>
    </>
  )
}

export default MainLayout
