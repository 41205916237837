import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Form, FormSpy, Field } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import RefreshIcon from '@material-ui/icons/Refresh'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import cookie from 'react-cookies'

import api from 'utils/api'
import MainLayout from 'layout/MainLayout'
import MDateRangePicker from 'components/forms/MDateRangePicker'
import MSortTable from 'components/MSortTable/MSortTable'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 200,
    },
    smFormControl: {
      minWidth: 120,
    },
    select: {
      minWidth: 110,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button: {
      height: 50,
      verticalAlign: 'middle',
    },
    table: {
      maxHeight: 'calc(100vh - 200px)',
      minHeight: 'calc(100vh - 200px)',
    },
    backDrop: {
      zIndex: 5,
    },
  }),
)

let defaultFilter = {
  dateRange: {
    startDate: new Date(),
    endDate: new Date(),
  },
}

const columns: TableColumnDef[] = [
  { field: 'page_location', headerName: 'URL', width: 'auto' },
  { field: 'form_flow', headerName: 'Flow', align: 'center', width: '50' },
  { field: 'views', headerName: 'Views', align: 'center', width: '70' },
  { field: 'leads', headerName: 'Leads', align: 'center', width: '100' },
  {
    field: 'conv_rate',
    headerName: 'Conversion Rate',
    align: 'center',
    width: '200',
  },
  {
    field: 'val_errors',
    headerName: 'Validation Errors',
    align: 'center',
    width: '200',
  },
  {
    field: 'back_actions',
    headerName: 'Back Actions',
    align: 'center',
    width: '200',
  },
]

type Props = {
  project?: string
}

const BlankComponent = () => (
  <>
  </>
)

const FormsPage: React.FC<Props> = ({ project }) => {
  const classes = useStyles()
  const [state, setState] = useState<{
    loading: boolean
    rows: any[]
    filter?: any
  }>({
    loading: true,
    rows: [],
    filter: defaultFilter,
  })

  const fetchData = async (params: any) => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }))

    try {
      const res = await api.get('/forms/getUrls', {
        ...params,
        ...params.dateRange,
        project: project,
        dateRange: undefined,
      })

      setState((prev) => ({
        ...prev,
        loading: false,
        rows: res,
        filter: params,
      }))
    } catch {
      setState((prev) => ({
        ...prev,
        loading: false,
      }))
    }
  }

  const onChange = (props: any) => {
    cookie.save('date-range', props.values.dateRange, { path: '/' })
    fetchData(props.values)
  }

  const onRowClick = (open: boolean, row: any) => {
    cookie.save('formsInfo', {...row, project:project}, {path:'/'})
    cookie.save('formsDateRange', state.filter.dateRange, {path: '/'})
    window.open('/forms-detail', '_blank')
  }

  useEffect(() => {
    fetchData(defaultFilter)
  }, []) //eslint-disable-line

  useEffect(() => {
    fetchData(state.filter)
  }, [project]) //eslint-disable-line

  const dateRange = cookie.load('date-range')
  defaultFilter.dateRange = dateRange || defaultFilter.dateRange
  
  return (
    <MainLayout title='Forms Page'>
      <>
        <Form
          initialValues={defaultFilter}
          onSubmit={fetchData}
          render={({ handleSubmit, values }) => (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <FormSpy subscription={{ values: true }} onChange={onChange} />
              <Grid container alignItems='center' spacing={2}>
                <Grid item style={{ marginLeft: 'auto' }}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='secondary'
                    className={classes.button}
                    startIcon={<RefreshIcon />}
                  >
                    Refresh Data
                  </Button>
                </Grid>
                <Grid item>
                  <Field
                    label='Date Range'
                    name='dateRange'
                    render={(props) => <MDateRangePicker {...props} />}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        />
        <MSortTable
          rows={state.rows}
          columns={columns}
          expandTable={true}
          detailComponent={BlankComponent}
          onExpand={onRowClick}
          paginate={false}
          className={classes.table}
          showComprehensiveData={true}
        />
        <Backdrop open={state.loading} className={classes.backDrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </>
    </MainLayout>
  )
}

const mapStateToProps = (state) => {
  return state.project
}
export default connect(mapStateToProps)(FormsPage)
