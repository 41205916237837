import { types } from './actions'

const initialState = null

function reducer(appState = initialState, { type, payload }) {
  switch (type) {
    case types.LOGIN:
      return payload
    case types.LOGOUT:
      return null
    default:
      return appState
  }
}

export function selectAuth(state) {
  return state.auth;
}

export default reducer;