import React, { useState, useEffect } from 'react'
import { Paper, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import MainLayout from 'layout/MainLayout'
import MSettingTable from 'components/MSettingTable'
import MPixelsDialog from 'components/MPixelsDialog'
import api from 'utils/api'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(3),
      minHeight: 'calc(100vh - 180px)',
    },
    formControl: {
      display: 'flex',
      minWidth: 200,
      maxWidth: 200,
      marginTop: theme.spacing(2),
    },
    subTitle: {
      marginTop: theme.spacing(3),
    },
    backDrop: {
      zIndex: 9999,
    },
  })
)

const columns = [
  { field: 'name', headerName: 'Name' },
  { field: 'status', headerName: 'Status' },
]

let defaultValue = {}

const PixelsPage = () => {
  const classes = useStyles()

  const [rows, setRows] = useState<any[]>([])
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleAdd = () => {
    defaultValue = {}
    setOpenDialog(true)
  }

  const confirmData = async (values, prevValue) => {
    //console.log(values)
    setLoading(true)
    try {
      let res
      if (Object.keys(prevValue).length === 0) {
        res = await api.get('/pixels/add', values)
      } else {
        res = await api.get('/pixels/update', { ...values, id: prevValue.id })
      }
      setRows(res)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const fetch = async () => {
    setLoading(true)
    try {
      const res = await api.get('/pixels')
      setRows(res)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const onDelete = async (index) => {
    setLoading(true)
    try {
      const res = await api.get('/pixels/delete', { id: index })
      setRows(res)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const onEdit = async (index) => {
    defaultValue = rows.find((row) => row.id === index)
    setOpenDialog(true)
  }

  useEffect(() => {
    fetch()
  }, [])

  return (
    <MainLayout title='Pixels'>
      <>
        <Paper className={classes.paper} elevation={3}>
          <Grid container style={{ marginBottom: '20px' }}>
            <Grid item>
              <Typography variant='h6'>Pixels</Typography>
            </Grid>
            <Grid item style={{ marginLeft: 'auto' }}>
              <Button color='primary' variant='contained' onClick={handleAdd}>
                {' '}
                New Pixel{' '}
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <MSettingTable
              columns={columns}
              rows={rows}
              handleProps={[
                { text: 'Edit', handler: onEdit },
                { text: 'Delete', handler: onDelete },
              ]}
            />
          </Grid>
        </Paper>

        <MPixelsDialog
          open={openDialog}
          setOpen={setOpenDialog}
          confirm={confirmData}
          defaultValue={defaultValue}
        />

        <Backdrop open={loading} className={classes.backDrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </>
    </MainLayout>
  )
}

export default PixelsPage
