import React, { useState, useRef, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import moment from 'moment'
import { TableSortLabel } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

import './MTreeTable.scoped.css'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHeader: {
      zIndex: 2,
      position: 'sticky',
      top: 0,
      background: 'white',
      boxShadow: '0 0 10px rgba(150,150,150,.2)',
    },
    treeHorizontalLine: {
      height: '100%',
      background: 'url(/tree/divider.png) repeat-x',
      top: '-24px',
      left: '20px',
      position: 'absolute',
    },
    tableSpan: {
      maxWidth: 'calc(90vw - 750px)',
    },
    tableCell: {
      fontSize: '14px',
      fontWeight: 500,
      paddingTop: '13px',
      paddingBottom: '13px',
      '&:nth-of-type(2n)': {
        backgroundColor: 'rgba(218, 220, 224, .2)',
      },
    },
    tableRow: {
      '&:nth-of-type(2n+1)': {
        backgroundColor: 'rgba(218, 220, 224, .2)',
      },
      '&:hover': {
        backgroundColor: 'rgba(218, 220, 224, .4)',
      },
    },
  }),
)

type Props = {
  rows?: any[]
  columns?: any[]
  className?: string
  onExpand: any
  maxDepth: number
  tableName: string
  defaultSort?: any
  showComprehensiveData?: boolean
}

type RowProps = {
  row: any
  columns: any[]
  parentIds: any[]
  tableIds: any[]
  onExpand: any
  maxDepth: number
}

const createHeaders = (headers) => {
  return headers.map((item) => ({
    ...item,
    ref: useRef(),
  }))
}

const MTreeRow: React.FC<RowProps> = ({
  row,
  columns,
  parentIds,
  tableIds,
  onExpand,
  maxDepth,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)

  const toggleOpen = async () => {
    if (!open) await onExpand(parentIds)
    setOpen(!open)
  }
  return (
    <React.Fragment>
      <TableRow
        onClick={parentIds.length < maxDepth ? toggleOpen : () => {}}
        className={classes.tableRow}
      >
        {columns.map((column, index) => {
          return (
            <>
              <TableCell
                key={column.field}
                align={column.align}
                className={classes.tableCell}
                width={column.width}
                style={{
                  ...(index === 0
                    ? {
                        paddingLeft: 20 + 20 * (tableIds.length - 1) + 'px',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        overflow: 'visible',
                      }
                    : {})
                }}
              >
                {index === 0 && (
                  <>
                    <div
                      className={
                        !(tableIds.length === 1 && tableIds[0] === 0)
                          ? classes.treeHorizontalLine
                          : ''
                      }
                      style={
                        !(tableIds.length === 1 && tableIds[0] === 0)
                          ? { width: 2 + 20 * (tableIds.length - 1) + 'px' }
                          : {}
                      }
                    ></div>
                    <div className='tree-vertical-line'></div>
                  </>
                )}
                <Tooltip
                  title={row[column.field] ? row[column.field] : 'Unknown'}
                  className={classes.tableSpan}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: column.renderer
                        ? column.renderer(row[column.field], row)
                        : row[column.field] === null
                        ? 'Unknown'
                        : row[column.field],
                    }}
                    className='span-dangerous'
                  ></span>
                </Tooltip>
              </TableCell>
            </>
          )
        })}
      </TableRow>
      {open &&
        row.children?.map((childrenRow, index) => {
          return (
            <MTreeRow
              key={index}
              row={childrenRow}
              columns={columns}
              parentIds={[...parentIds, index]}
              tableIds={[...tableIds, index]}
              onExpand={onExpand}
              maxDepth={maxDepth}
            />
          )
        })}
    </React.Fragment>
  )
}

const MTreeTable: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { rows = [], columns = [], className, maxDepth, onExpand, defaultSort, showComprehensiveData = false } = props
  
  const [orderBy, setOrderBy] = useState<any>(defaultSort)
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')

  const [comprehensiveData, setComprehensiveData] = useState<any>()

  const cols = createHeaders(columns)

  const createSortHandler = (field: any) => {
    field === orderBy
      ? setOrder(order === 'asc' ? 'desc' : 'asc')
      : setOrderBy(field)
  }

  const stableSort = (array: any[] = []) => {
    const stabilizedThis = Array.isArray(array) ? (array).map((el) => el) : []
    stabilizedThis.sort((a, b) => {
      let f, s
      if (orderBy === 'start_time') {
        f = a[orderBy] === null || a[orderBy] === '' ? 0xffff: moment(a[orderBy], 'dddd, MMM D, YYYY LT').unix()
        s = b[orderBy] === null || b[orderBy] === '' ? 0xffff: moment(b[orderBy], 'dddd, MMM D, YYYY LT').unix()
      } else if(orderBy === 'ctr' || orderBy === 'conv_rate' || orderBy === 'bounce_rate') {
        f = parseFloat(a[orderBy].slice(0, a[orderBy].length - 1))
        s = parseFloat(b[orderBy].slice(0, b[orderBy].length - 1))
      } else if (typeof a[orderBy] === 'string' && (a[orderBy]?.endsWith('AM') || a[orderBy]?.endsWith('PM'))) {
        f = parseInt(a[orderBy].slice(0, a[orderBy].length - 3))%12
        s = parseInt(b[orderBy].slice(0, b[orderBy].length - 3))%12
        if (a[orderBy]?.endsWith('PM')) {
          f += 12
        } if (b[orderBy]?.endsWith('PM')) {
          s += 12
        }
      } else if (typeof a[orderBy] === 'string') {
        f = a[orderBy] === null || a[orderBy] === '' ? 0xffff: a[orderBy].toUpperCase()
        s = b[orderBy] === null || b[orderBy] === '' ? 0xffff: b[orderBy].toUpperCase()
      } else {
        f = a[orderBy] === null || a[orderBy] === '' ? 0xffff: a[orderBy]
        s = b[orderBy] === null || b[orderBy] === '' ? 0xffff: b[orderBy]
      }
      return order === 'asc' ? (f > s ? 1 : -1) : (s > f ? 1: -1)
    })
    return stabilizedThis.map((el) => el)
  }

  useEffect(() => {
    let totalCalc = {}
    columns.forEach((column) => {
      totalCalc[column.field] = 0
      rows.forEach((row) => {
        totalCalc[column.field] += parseInt(row[column.field])
      })
    })
    totalCalc['engagement'] = 0
    rows.forEach(row => {
      totalCalc['engagement'] += parseInt(row['engagement'])
    })
    totalCalc['dimension1'] = 'Totals'
    let clicks = parseInt(totalCalc['clicks'])
    let views =  parseInt(totalCalc['views'])
    let leads = parseInt(totalCalc['leads'])
    let engagement = parseInt(totalCalc['engagement'])
    let requests = parseInt(totalCalc['requests'])

    totalCalc['ctr'] = (views ? (clicks * 100 / views).toFixed(1) : 0) + '%'
    totalCalc['conv_rate'] = (views ? (leads * 100 / views).toFixed(1) : 0) + '%'
    totalCalc['bounce_rate'] = (views ? (engagement * 100 / views).toFixed(1) : 0) + '%'
    totalCalc['cr'] = (requests ? (clicks * 100 / requests).toFixed(1) : 0) + '%'

    setComprehensiveData(totalCalc)
  }, [rows, columns])


  return (
    <>
    <TableContainer className={className} component={Paper}>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {cols.map(
              (column, index) =>
                column.headerName && (
                  <>  
                    <TableCell
                      ref={column.ref}
                      key={column.field}
                      align={column.align}
                      width={column.width}
                      style={{ 
                        fontSize: '16px', 
                        fontWeight: 'bold',
                      }}
                    >
                      <TableSortLabel
                      active={orderBy === column.field}
                      direction={orderBy === column.field ? order : 'asc'}
                      onClick={() => createSortHandler(column.field)}
                    >
                      <span style={{color: 'rgb(90,90,90)', fontSize:'18px',}}>{column.headerName}</span>
                    </TableSortLabel>
                      <span style={{marginTop: 20}}>{comprehensiveData && comprehensiveData[column.field]}</span>
                    </TableCell>
                  </>
                ),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          { stableSort(rows)?.map((row, ind) => {
            let oIndex
            rows.forEach((oRow, index) => { 
              if(row === oRow) {
                oIndex = index
              }
            })
            return (
              <MTreeRow
                key={oIndex||row.id}
                row={row}
                columns={columns}
                parentIds={[oIndex]}
                tableIds={[ind]}
                onExpand={onExpand}
                maxDepth={maxDepth}
              />
            )
          })}

          {!rows.length && (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                align='center'
              >
                No records found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    {/* { showComprehensiveData && <MFooterTable rows={comprehensiveData} columns={columns} />} */}
    </>
  )
}

export default MTreeTable
