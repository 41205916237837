import React from 'react'
import { Form, FormSpy, Field } from 'react-final-form'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import MSelect from './forms/MSelect'
import MEdit from 'components/forms/MEdit'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      display: 'flex',
      minWidth: '100%',
      maxWidth: '100%',
      marginBottom: theme.spacing(5),
    },
  }),
)

type Props = {
  open: boolean
  setOpen: any
  confirm: any
}

const MIntegrationDialog: React.FC<Props> = ({ open, setOpen, confirm }) => {
  const classes = useStyles()

  const handleEditClose = () => {
    setOpen(false)
  }

  const handleAdd = async (values) => {
    await confirm(values)
    setOpen(false)
  }

  const onChange = (props) => {}

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={open}
      onClose={handleEditClose}
      aria-labelledby='max-width-dialog-title'
    >
      <DialogTitle id='max-width-dialog-title'>Add Integrations</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={confirm}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormSpy subscription={{ values: true }} onChange={onChange} />
              <Field
                className={classes.formControl}
                name='ad_platform'
                label='Ad Platform'
                variantType='standard'
                render={(props) => (
                  <MSelect
                    {...props}
                    data={['Taboola', 'Outbrain', 'Yahoo DSP']}
                  />
                )}
              />

              {values.ad_platform === 'Taboola' && (
                <>
                  <Field
                    className={classes.formControl}
                    name='id'
                    label='Client ID'
                    variantType='standard'
                    render={(props) => <MEdit {...props} />}
                  />

                  <Field
                    className={classes.formControl}
                    name='pass'
                    label='Cliend Secret'
                    variantType='standard'
                    render={(props) => <MEdit {...props} />}
                  />
                </>
              )}

              {values.ad_platform === 'Yahoo DSP' && (
                <Field
                  className={classes.formControl}
                  name='token'
                  label='Access Token'
                  variantType='standard'
                  render={(props) => <MEdit {...props} />}
                />
              )}
              <DialogActions>
                <Button onClick={() => handleAdd(values)} color='primary'>
                  Confirm
                </Button>
                <Button onClick={handleEditClose} color='primary'>
                  Close
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}

export default MIntegrationDialog
