import React, { useState, useEffect } from 'react'
import Collapse from '@material-ui/core/Collapse'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Tooltip } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      maxWidth: '250px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  }),
)

type DetailRowProps = {
  rows: any[]
  columns: TableColumnDef[]
}
type Props = {
  columns: TableColumnDef[]
  className?: string
  rows: Array<any>
  expandTable?: boolean
  detailComponent?: React.FC<DetailRowProps>
  onExpand?: any
  stickyHeader?: boolean
}

type RowProps = {
  row: any
  index: number
  tableProps: Props
}

const stepValueColumns: TableColumnDef[] = [
  { field: 'step_value', headerName: 'Step Value' },
  { field: 'count', headerName: 'Amount Selected', align: 'center' },
  { field: 'rate', headerName: '% of Total', align: 'center' },
]

const nameValueColumns: TableColumnDef[] = [
  { field: 'first_name', headerName: 'First Name' },
  { field: 'last_name', headerName: 'Last Name', align: 'center' },
]

const MTableRow: React.FC<RowProps> = ({ row, index, tableProps }) => {
  const classes = useStyles()
  const { columns, expandTable, onExpand, detailComponent } = tableProps
  const [open, setOpen] = useState<boolean>(false)

  const toggleDetail = async () => {
    await onExpand(open, index)
    setOpen(!open)
  }

  const getDetail = () => {
    var props
    if (row['event_name']) {//WHEN SESSION DETAIL PAGE
      props = { rows: row.detailRows, columns: columns }
    } else if ( row['step_title'] && row['step_title'] === 'What is your name?' ) {//WHEN WHAT IS YOUR NAME IN FORMS PAGE
      props = { rows: row.detailRows, columns: nameValueColumns }
    } else { //DEFAULT
      props = { rows: row.detailRows, columns: stepValueColumns }
    }

    const rowDetail = React.createElement(detailComponent, props)
    return rowDetail
  }

  useEffect(() => {
    setOpen(false)
  }, [row])
  return (
    <React.Fragment>
      <TableRow onClick={expandTable ? toggleDetail : () => {}} hover>

        {columns.map(
          (column) =>
            column.headerName && (
              <TableCell
                key={column.field}
                align={column.align}
                className={classes.tableCell}
                style={
                  row['event_name'] && row['event_name'] !== ' '
                    ? {
                        borderTop: '1px solid rgba(224, 224, 224, 1)',
                        borderBottom: 'none',
                      }
                    : {
                        border: 'none',
                      }
                }
              >
                <Tooltip
                  title={
                    column.headerName === 'First Visit URL' || column.headerName === 'Event Param Values'
                      ? row[column.field] : ''
                    }
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: column.renderer
                        ? column.renderer(row[column.field], row)
                        : row[column.field] === null
                        ? 'Unknown'
                        : row[column.field],
                    }}
                    style={
                      column.field === 'event_param_values' &&
                      row[column.field] === 'pass'
                        ? { color: '#2FA033' }
                        : column.field === 'event_param_values' &&
                          row[column.field] === 'error'
                        ? { color: 'red' }
                        : {}
                    }
                  ></span>
                </Tooltip>
              </TableCell>
            ),
        )}
      </TableRow>

      {expandTable && (
        <TableRow style={{ width: '100%' }}>
          <TableCell style={{ padding: 0 }} colSpan={columns.length + 1}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              {getDetail()}
              {/* <React.Component component={detailComponent} rows={row.detailRows} /> */}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

const MTable: React.FC<Props> = (props) => {
  const { rows, columns, className, expandTable, stickyHeader = true } = props

  return (
    <TableContainer className={className} component={Paper}>
      <Table stickyHeader={stickyHeader}>
      <TableHead>
          <TableRow style={{ zIndex: 1 }}>
            {columns.map(
              (column) =>
                column.headerName && (
                  <TableCell
                    key={column.field}
                    width={column.width}
                    align={column.align}
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                  >
                    {column.headerName}
                  </TableCell>
                ),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <MTableRow
              key={row.id || index}
              row={row}
              index={index}
              tableProps={props}
            />
          ))}

          {!rows.length && (
            <TableRow>
              <TableCell
                colSpan={expandTable ? columns.length + 1 : columns.length}
                align='center'
              >
                No records found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MTable
