import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles } from '@material-ui/core/styles'

type Props = {
  open: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    backDrop: {
      zIndex: 2,
    },
  }),
)

const MSpinner: React.FC<Props> = ({ open }) => {
  const classes = useStyles()

  return (
    <Backdrop open={open} className={classes.backDrop}>
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}

export default MSpinner