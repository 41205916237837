import { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MTable from 'components/MTable'

type Props = {
  rows: any[]
  columns: any[]
}

const DetailStatistics: React.FC<Props> = ({
  rows,
  columns,
}) => {
  const [failedRows, setFailedRows] = useState<any[]>([])

  useEffect(() => {
    let failedValRows = []

    for (let row of rows) {
      if (row.validation_status === 'error') {
        failedValRows.push(row)
      }
    }
    
    if (failedValRows[0]?.count) {
      failedValRows.sort((a, b) => {
        return b.count - a.count
      })
    }
    
    setFailedRows(failedValRows)
  }, [rows])

  return (
    <Box margin={1} style={{ width: '60%' }}>
      <Typography variant='h6' gutterBottom component='div'>
        Details
      </Typography>
      <MTable rows={failedRows} columns={columns} stickyHeader={false} />
    </Box>
  )
}
export default DetailStatistics
