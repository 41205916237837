import { TableRow, TableCell, Tooltip, TableBody, Table } from '@material-ui/core'
import React from 'react'

type Props = {
  rows: any[]
  columns: any[]
}
const DetailRows: React.FC<Props> = ({ rows, columns }) => {
  return (
    <Table>
    <TableBody>
      {rows.map((row, index) => {
        return (
          <TableRow key={row.id || index}>
            {columns.map((column, index) => {
              return (
                <TableCell
                  key={column.field}
                  align={column.align}
                  width={column.width}
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Tooltip title={row[column.field]}>
                    <span>{row[column.field]}</span>
                  </Tooltip>
                </TableCell>
              )
            })}
          </TableRow>
        )
      })}
    </TableBody>
    </Table>
  )
}

export default DetailRows
