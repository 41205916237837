import { Switch, Route, Redirect } from 'react-router-dom'

import CPCListingsPage from 'pages/CPC-ListingsPage'
import FormsPage from 'pages/FormsPage'
import LoginPage from 'pages/LoginPage'
import SessionExplorerPage from 'pages/SessionExplorerPage'
import SessionDetailPage from 'pages/SessionExplorerPage/SessionDetailPage'
import SourcePage from 'pages/SourcesPage'
import LandingPage from 'pages/LandingPage'
import LandingDetailPage from 'pages/LandingPage/LandingDetailPage'
import ProjectPage from 'pages/ProjectPage'
import IntegrationsPage from 'pages/IntegrationsPage'
import FormsDetailPage from 'pages/FormsPage/FormsDetailPage'
import EventsPage from 'pages/EventsPage/GA4EventsPage'
import ServerEventsPage from 'pages/EventsPage/ServerEventsPage'
import ManageKeys from 'pages/EventsPage/ServerEventsPage/ManageKeys'
import SourcePairingPage from 'pages/SourcePairingPage'
import PixelsPage from 'pages/PixelsPage'
const App = () => {
  return (
    <Switch>
      <Route path='/' exact>
        <SourcePage />
      </Route>
      <Route path='/forms' exact>
        <FormsPage />
      </Route>
      <Route path='/forms-detail' exact>
        <FormsDetailPage />
      </Route>
      <Route path='/landing-page' exact>
        <LandingPage />
      </Route>
      <Route path='/landing-detail' exact>
        <LandingDetailPage />
      </Route>
      <Route path='/cpc-listings' exact>
        <CPCListingsPage />
      </Route>
      <Route path='/login' exact>
        <LoginPage />
      </Route>
      <Route path='/session-explorer' exact>
        <SessionExplorerPage />
      </Route>
      <Route path='/session-detail'>
        <SessionDetailPage />
      </Route>
      <Route path='/project'>
        <ProjectPage />
      </Route>
      <Route path='/integrations'>
        <IntegrationsPage />
      </Route>
      <Route path='/source-pairing'>
        <SourcePairingPage />
      </Route>
      <Route path='/pixels'>
        <PixelsPage />
      </Route>
      <Route path='/ga4-events'>
        <EventsPage />
      </Route>
      <Route exact path='/server-events'>
        <ServerEventsPage />
      </Route>
      <Route path='/server-events/manage-keys'>
        <ManageKeys />
      </Route>
      <Redirect to='/redirect' />
    </Switch>
  )
}

export default App
