import CPCListingsPage from './CPCListingsPage'
import { SnackbarProvider } from 'notistack';

const CPCListingPage = () => {
  return (
    <SnackbarProvider maxSnack={1}>
      <CPCListingsPage />
    </SnackbarProvider>
  )
}

export default CPCListingPage
