export const dimensionList = [
  {
    id: 'traffic_source.source',
    text: 'Source',
  },
  {
    id: 'page_location',
    text: 'Page Location',
  },
  {
    id: 'page_referrer',
    text: 'Page Referrer',
  },
  {
    id: 'page_title',
    text: 'Page Title',
  },
  {
    id: 'page_type',
    text: 'Page Type',
  },
  {
    id: 'page_version',
    text: 'Page Version',
  },
  {
    id: 'form_version',
    text: 'Form Version',
  },
  {
    id: 'form_flow',
    text: 'Form Flow',
  },
  {
    id: 'device.category',
    text: 'Device Category',
  },
  {
    id: 'device.mobile_brand_name',
    text: 'Device Mobile Brand Name',
  },
  {
    id: 'device.mobile_model_name',
    text: 'Device Mobile Model Name',
  },
  {
    id: 'device.mobile_marketing_name',
    text: 'Device Marketing Name',
  },
  {
    id: 'device.operating_system',
    text: 'Device OS',
  },
  {
    id: 'device.operating_system_version',
    text: 'Device OS Version',
  },
  {
    id: 'device.web_info.browser',
    text: 'Device Browser',
  },
  {
    id: 'device.web_info.browser_version',
    text: 'Device Browser Version',
  },
  {
    id: 'geo.country',
    text: 'Geo Country',
  },
  {
    id: 'geo.region',
    text: 'Geo Region',
  },
  {
    id: 'geo.city',
    text: 'Geo City',
  },
  {
    id: 'event_timestamp',
    text: 'Hourly Parting',
  },
  {
    id: 'event_date',
    text: 'Day Parting',
  },
  {
    id: 'campaign_id',
    text: 'Campaign ID',
  },
  {
    id: 'group_id',
    text: 'Group ID',
  },
  {
    id: 'ad_id',
    text: 'Ad ID',
  },
  {
    id: 'ad_site_id',
    text: 'Ad Site ID',
  },
  {
    id: 'ad_site_name',
    text: 'Ad Site Name',
  },
  {
    id: 'ad_site_url',
    text: 'Ad Site URL',
  },
  {
    id: 'ad_placement',
    text: 'Ad Placement',
  },
  {
    id: 't1',
    text: 'T1',
  },
  {
    id: 't2',
    text: 'T2',
  },
  {
    id: 't3',
    text: 'T3',
  },
  {
    id: 't4',
    text: 'T4',
  },
  {
    id: 't5',
    text: 'T5',
  },
  {
    id: 'ga_session_id',
    text: 'Session ID',
  },
  {
    id: 'link_value',
    text: 'Link Value',
  },
  {
    id: 'link_type',
    text: 'Link Type',
  },
  {
    id: 'validation_status',
    text: 'Validation Status',
  },
  {
    id: 'cpc_version',
    text: 'CPC Version',
  },
  {
    id: 'cpc_listing_name',
    text: 'CPC Listing Name',
  },
  {
    id: 'cpc_placement_id',
    text: 'CPC Placement ID',
  },
  {
    id: 'cpc_placement_name',
    text: 'CPC Placement Name',
  },
  {
    id: 'cpc_position',
    text: 'CPC Position',
  },
]

export const deviceList = ['All', 'Desktop', 'Tablet', 'Mobile']

export const timezoneList = [
  { id: 'America/Chicago', text: 'Chicago' },
  { id: 'America/New_York', text: 'New York' },
]

export const dFilterList = [
  {
    id: 'traffic_source.source',
    text: 'Source',
  },
  {
    id: 'page_location',
    text: 'Page Location',
  },
  {
    id: 'page_referrer',
    text: 'Page Referrer',
  },
  {
    id: 'page_title',
    text: 'Page Title',
  },
  {
    id: 'page_type',
    text: 'Page Type',
  },
  {
    id: 'page_version',
    text: 'Page Version',
  },
  {
    id: 'form_version',
    text: 'Form Version',
  },
  {
    id: 'form_flow',
    text: 'Form Flow',
  },
  {
    id: 'device.category',
    text: 'Device Category',
  },
  {
    id: 'device.mobile_brand_name',
    text: 'Device Mobile Brand Name',
  },
  {
    id: 'device.mobile_model_name',
    text: 'Device Mobile Model Name',
  },
  {
    id: 'device.mobile_marketing_name',
    text: 'Device Marketing Name',
  },
  {
    id: 'device.operating_system',
    text: 'Device OS',
  },
  {
    id: 'device.operating_system_version',
    text: 'Device OS Version',
  },
  {
    id: 'device.web_info.browser',
    text: 'Device Browser',
  },
  {
    id: 'device.web_info.browser_version',
    text: 'Device Browser Version',
  },
  {
    id: 'geo.country',
    text: 'Geo Country',
  },
  {
    id: 'geo.region',
    text: 'Geo Region',
  },
  {
    id: 'geo.city',
    text: 'Geo City',
  },
  {
    id: 'event_timestamp',
    text: 'Hourly Parting',
  },
  {
    id: 'event_date',
    text: 'Day Parting',
  },
  {
    id: 'campaign_id',
    text: 'Campaign ID',
  },
  {
    id: 'group_id',
    text: 'Group ID',
  },
  {
    id: 'ad_id',
    text: 'Ad ID',
  },
  {
    id: 'ad_site_id',
    text: 'Ad Site ID',
  },
  {
    id: 'ad_site_name',
    text: 'Ad Site Name',
  },
  {
    id: 'ad_site_url',
    text: 'Ad Site URL',
  },
  {
    id: 'ad_placement',
    text: 'Ad Placement',
  },
  {
    id: 't1',
    text: 'T1',
  },
  {
    id: 't2',
    text: 'T2',
  },
  {
    id: 't3',
    text: 'T3',
  },
  {
    id: 't4',
    text: 'T4',
  },
  {
    id: 't5',
    text: 'T5',
  },
  {
    id: 'page_view_count',
    text: 'Page View',
  },
  {
    id: 'landing_page_click_count',
    text: 'Landing Page Click',
  },
  {
    id: 'form_click_count',
    text: 'Form Click',
  },
  {
    id: 'lead_submit_count',
    text: 'Lead Submit',
  },
  {
    id: 'cpc_request_count',
    text: 'CPC Request',
  },
  {
    id: 'cpc_click_count',
    text: 'CPC Click',
  },
  {
    id: 'link_value',
    text: 'Link Value',
  },
  {
    id: 'link_type',
    text: 'Link Type',
  },
  {
    id: 'validation_status',
    text: 'Validation Status',
  },
  {
    id: 'cpc_version',
    text: 'CPC Version',
  },
  {
    id: 'cpc_listing_name',
    text: 'CPC Listing Name',
  },
  {
    id: 'cpc_placement_id',
    text: 'CPC Placement ID',
  },
  {
    id: 'cpc_placement_name',
    text: 'CPC Placement Name',
  },
  {
    id: 'cpc_position',
    text: 'CPC Position',
  },
]

export const eventTypeData = {
  first_visit: ['page_location'],
  session_start: ['page_location'],
  page_view: [
    'page_location',
    'page_type',
    'page_version',
    'form_version',
    'form_flow',
  ],
  landing_page_click: [
    'page_location',
    'page_type',
    'page_version',
    'link_type',
    'link_value',
  ],
  form_click: [
    'step_question',
    'step_value',
    'step_action',
    'validation_status',
  ],
  lead_submit: [
    'user_first_name',
    'user_last_name',
    'user_email_address',
    'user_phone_number',
  ],
  cpc_request: [
    'page_location',
    'cpc_version',
    'cpc_placement_id',
    'cpc_placement_name',
  ],
  cpc_click: [
    'page_location',
    'cpc_version',
    'cpc_placement_id',
    'cpc_placement_name',
    'cpc_listing_name',
    'cpc_position',
  ],
  user_engagement: [
    'page_location',
  ],
  scroll: [
    'page_location',
  ]
}

export const eventDimensionList = [
  {
    id: 'event_name',
    text: 'Event Name'
  },
  {
    id: 'ga_client_id',
    text: 'Client ID'
  },
  {
    id: 'ga_session_id',
    text: 'Session ID'
  },
  {
    id: 'domain',
    text: 'Domain'
  },
  {
    id: 'sale_type',
    text: 'Sale Type'
  },
  {
    id: 'sale_tier',
    text: 'Sale Tier'
  },
  {
    id: 'buyer',
    text: 'Buyer'
  },
  {
    id: 'sub_buyer',
    text: 'Sub Buyer'
  },
  {
    id: 'pub_name',
    text: 'Pub Name'
  },
  {
    id: 'pub_token',
    text: 'Pub Token'
  },
  {
    id: 'pub_cost',
    text: 'Pub Cost'
  },
  {
    id: 'sale_revenue',
    text: 'Sale Revenue'
  },
  {
    id: 'source',
    text: 'Source'
  },
  {
    id: 'campaign_id',
    text: 'Campaign ID'
  },
]