import { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import {
  Card,
  CardContent,
  Divider,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import Grid from '@material-ui/core/Grid'
import queryString from 'query-string'
import api from 'utils/api'
import MainLayout from 'layout/MainLayout'
import MSortTable from 'components/MSortTable/MSortTable'
import MSpinner from 'components/forms/MSpinner'
import DetailRows from 'components/pages/forms/DetailRows'
import { eventTypeData } from '../../data/options'

const columns: any[] = [
  { field: 'event_timestamp', headerName: 'Event Timestamp', width: 250 },
  {
    field: 'elapsed_time',
    headerName: 'Elapsed Time',
    align: 'center',
    width: 100,
  },
  { field: 'event_name', headerName: 'Event Name', align: 'center', width:150 },
  { field: 'event_params', headerName: 'Event Params', align: 'right', width:150 },
  {
    field: 'event_param_values',
    headerName: 'Event Param Values',
    align: 'left',
    width: 'auto',
  },
]

const propertyColumns: any[] = [
  { field: 'source', headerName: 'Source' },
  { field: 'geo_source', headerName: 'Geo Source' },
  { field: 'geo_region', headerName: 'Geo Region' },
  { field: 'geo_zip', headerName: 'Zip' },
  { field: 'campaign_id', headerName: 'Campaign ID' },
  { field: 'group_id', headerName: 'Group ID' },
  { field: 'ad_id', headerName: 'Ad ID' },
  { field: 'ad_site_id', headerName: 'Ad Site ID' },
  { field: 'ad_site_name', headerName: 'Ad Site Name' },
  { field: 'ad_site_url', headerName: 'Ad Site URL' },
  { field: 'ad_placement', headerName: 'Ad Placement' },
  { field: 'ad_click_id', headerName: 'Ad Click ID' },
  { field: 'voluum_click_id', headerName: 'Voluum Click ID' },
  { field: 't1', headerName: 'T1' },
  { field: 't2', headerName: 'T2' },
  { field: 't3', headerName: 'T3' },
  { field: 't4', headerName: 'T4' },
  { field: 't5', headerName: 'T5' },
  //{ field: 'screen_resolution', headerName: 'Screen Resolution' },
  { field: 'device.category', headerName: 'Device Category' },
  { field: 'device.mobile_model_name', headerName: 'Device Model' },
  { field: 'device.operating_system', headerName: 'Operating System' },
  { field: 'device.operating_system_version', headerName: 'OS Version' },
  { field: 'device.web_info.browser', headerName: 'Browser' },
  { field: 'geo.city', headerName: 'Geo City' },
  { field: 'geo.region', headerName: 'Geo Region' },
  { field: 'geo.country', headerName: 'Geo Country' },
  //{ field: 'user_ip_addr', headerName: 'IP Address' },
  //{ field: 'user_first_name', headerName: 'First Name' },
  //{ field: 'user_last_name', headerName: 'Last Name' },
  //{ field: 'user_email_addr', headerName: 'Email Address' },
  //{ field: 'user_phone_num', headerName: 'Phone Number' },
]

const SessionDetailPage = () => {
  const [state, setState] = useState<{
    loading: boolean
    session_id: any
    rows: any[]
  }>({
    session_id: null,
    loading: true,
    rows: [],
  })

  const [property, setProperty] = useState<any>()
  const [simplifiedRows, setSimplifiedRows] = useState<any[]>([])
  const [viewMode, setViewMode] = useState<'simplify'|'raw'>('simplify')
  
  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: any | null) => {
    setViewMode(newAlignment);
  };

  const fetchTableData = async (session_id, date_range, project) => {
    //console.log(session_id, date_range)
    const res = await api.get('/sessions/details', {
      proejct: project,
      session_id: session_id,
      startDate: date_range.startDate,
      endDate: date_range.endDate,
    })

    let tempSimpleRows = []
    res.forEach(row => {
      let tempRows = []
      if ( eventTypeData[row.event_name].indexOf(row.event_params) >= 0) 
        tempRows.push(row)
      row.detailRows?.forEach(detailRow => {
        if ( eventTypeData[row.event_name].indexOf(detailRow.event_params) >= 0 )
          tempRows.push(detailRow) 
      })
      tempRows.sort((a, b) => {
        return eventTypeData[row.event_name].indexOf(a.event_params) - eventTypeData[row.event_name].indexOf(b.event_params)
      })
      tempRows.forEach((tempRow,index) => {
        if (index === 0) {
          tempRow.event_timestamp = row.event_timestamp
          tempRow.elapsed_time = row.elapsed_time
          tempRow.event_name = row.event_name
        } else {
          tempRow.event_timestamp = ''
          tempRow.elapsed_time = ''
          tempRow.event_name = ''
        }
      })
      tempSimpleRows.push(...tempRows)
    })

    setSimplifiedRows(tempSimpleRows)

    setState((prev) => ({
      ...prev,
      session_id: session_id,
      rows: res,
    }))
  }

  const fetchPropertyData = async (session_id, date_range, project) => {
    const res = await api.get('/sessions/property', {
      session_id: session_id,
      project: project,
      propertyData: propertyColumns,
      startDate: date_range.startDate,
      endDate: date_range.endDate,
    })

    setProperty(res)
    setState((prev) => ({
      ...prev,
      loading: false,
    }))
  }
  useEffect(() => {
    const queryParams = queryString.parse(window.location.search)

    const session_id = queryParams.session_id
    const date_range = cookie.load('date-range')
    const project = cookie.load('project')
    setState({
      loading: true,
      rows: [],
      session_id: session_id,
    })
    fetchTableData(session_id, date_range, project)
    fetchPropertyData(session_id, date_range, project)
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainLayout title='Session Detail Page'>
      <>
        <Grid container alignItems='center' spacing={2}>
          <Grid item>
            <h2>Session ID:{state.session_id}</h2>
          </Grid>
          <Grid item>
            <ToggleButtonGroup exclusive value={viewMode} onChange={handleAlignment}>
              <ToggleButton value='simplify'>SIMPLIFIED VIEW</ToggleButton>
              <ToggleButton value='raw'>RAW VIEW</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={9} lg={9}>
            { viewMode === 'raw' && 
              <MSortTable 
                rows={state.rows} 
                columns={columns} 
                detailComponent={DetailRows} 
                onExpand={()=>{}} 
                expandTable={true}
                paginate={false}
              /> 
            }
            { viewMode === 'simplify' && 
              <MSortTable 
                rows={simplifiedRows}
                columns={columns}
                paginate={false}
                sort={false}
              />
            }
          </Grid>
          <Grid item style={{ marginLeft: 'auto' }} xs={3} lg={3}>
            <Card
              style={{ position: 'fixed', right: '20px', width: 'calc(25vw - 30px)'}}
              variant='outlined'
            >
              <CardContent>
                <Typography variant='h5' component='h3'>
                  User Properties
                </Typography>
                <Divider />
                {propertyColumns.map((item) => {
                  return (
                    <Grid container>
                      <Grid
                        item
                        xs={5}
                        lg={5}
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 'bold',
                          fontSize: '11px'
                        }}
                      >
                        {item.headerName}
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        lg={5}
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontSize: '11px'
                        }}
                      >
                        <Tooltip
                          title={
                            property && property[item.field]
                              ? property[item.field]
                              : '?'
                          }
                        >
                          <span>
                          {property && property[item.field]
                            ? property[item.field]
                            : '?'}
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )
                })}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <MSpinner open={state.loading} />
      </>
    </MainLayout>
  )
}

export default SessionDetailPage