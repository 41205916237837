import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

type Props = {
  label?: string
  previousData?: any
  input?: any
  defaultChecked?: boolean
}

const MCheckbox: React.FC<
  Props & React.InputHTMLAttributes<HTMLInputElement>
> = ({ className, input, label, defaultChecked }) => {

  delete input.checked
  return (
    <FormControlLabel
      className={className}
      control={
        <Checkbox
          {...input}
          defaultChecked={defaultChecked}
          name='checkedB'
          color='primary'
        />
      }
      label={label}
    />
  )
}

export default MCheckbox
