import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import { TableSortLabel, Tooltip } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import moment from 'moment'
import './MSortTable.scoped.css'

type DetailRowProps = {
  rows: any[]
  columns: TableColumnDef[]
}

type Props = {
  columns: TableColumnDef[]
  rows: Array<any>
  className?: string
  expandTable?: boolean
  detailComponent?: React.FC<DetailRowProps>
  onExpand?: any
  stickyHeader?: boolean
  defaultSort?:any
  paginate?: boolean
  sort?: boolean
  showComprehensiveData?: boolean
}

type RowProps = {
  row: any
  index: number
  tableProps: Props
}

const stepValueColumns: TableColumnDef[] = [
  { field: 'count', headerName: 'Amount Selected', align: 'center' },
  { field: 'rate', headerName: '% of Total', align: 'center' },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHeader: {
      zIndex: 2,
      position: 'sticky',
      top: 0,
      background: 'white',
      boxShadow: '0 0 10px rgba(150,150,150,.2)',
    },
    tableCell: {
      fontSize: '14px',
      fontWeight: 500,
      paddingTop: '10px',
      paddingBottom: '10px',
      '&:nth-of-type(2n)': {
        backgroundColor: 'rgba(218, 220, 224, .2)',
      },
    },
    tableRow: {
      '&:nth-of-type(2n+1)': {
        backgroundColor: 'rgba(218, 220, 224, .2)',
      },
      '&:hover': {
        backgroundColor: 'rgba(218, 220, 224, .4)',
      },
    },
  }),
)

const MSortTableRow: React.FC<RowProps> = ({ row, index, tableProps }) => {
  const classes = useStyles()
  const { columns, expandTable, onExpand, detailComponent } = tableProps
  const [open, setOpen] = useState<boolean>(false)

  const toggleDetail = async () => {
    await onExpand(open, row)
    setOpen(!open)
  }

  const getDetail = () => {
    var props
    if (row['event_name']) {
      //WHEN SESSION DETAIL PAGE
      props = { rows: row.detailRows, columns: columns }
    } else {
      let propColumns = [...stepValueColumns]
      let tmpObj = {}
      if ( row.detailRows) {
        for (let i = 0; i < row.detailRows.length; i++ ) {
          for(let key of Object.keys(row.detailRows[i])) {
            if (key !== 'count' && key !== 'rate' && key !== 'validation_status') {
              tmpObj[key] = key.toUpperCase()
            }
          }
        }
        for (let key of Object.keys(tmpObj)) {
          propColumns.splice(0, 0, { field: key, headerName: key.toUpperCase() })
        }
      }
      
      props = { rows: row.detailRows, columns: propColumns }
    }
    
    const rowDetail = React.createElement(detailComponent, props)
    return rowDetail
  }

  
  useEffect(() => {
    setOpen(false)
  }, [row])

  return (
    <React.Fragment>
      <TableRow 
        onClick={expandTable ? toggleDetail : () => {}} 
        className={classes.tableRow}
      >

        {columns.map(
          (column, id) =>
            column.headerName && (
              <TableCell
                key={column.field}
                align={column.align}
                width={column.width}
                className={classes.tableCell}
              >
                <Tooltip
                  title={
                    column.headerName === 'First Visit URL' ||
                    column.headerName === 'Event Param Values' ||
                    column.headerName === 'Start Time'
                      ? row[column.field]
                      : ''
                  }
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: column.renderer
                        ? column.renderer(row[column.field], row)
                        : row[column.field] === null
                        ? 'Unknown'
                        : row[column.field],
                    }}
                  ></span>
                </Tooltip>
              </TableCell>
            ),
        )}
      </TableRow>

      {expandTable && open && (
        <TableRow style={{ width: '100%' }}>
          <TableCell style={{ padding: 0 }} colSpan={columns.length + 1}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              {getDetail()}
              {/* <React.Component component={detailComponent} rows={row.detailRows} /> */}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

const CreateHeaders = (headers) => {
  const ref = useRef()

  return headers.map((item) => ({
    ...item,
    ref: ref,
  }))
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{flexShrink: 0, marginLeft: '10px'}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const MSortTable: React.FC<Props> = (props) => {
  const { 
    rows, 
    columns, 
    className, 
    stickyHeader = true, 
    defaultSort, 
    sort = true,
    paginate = true,
    showComprehensiveData = false
  } = props

  const classes = useStyles()
  const [orderBy, setOrderBy] = useState<any>(defaultSort)
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')
  const [comprehensiveData, setComprehensiveData] = useState<any>({})
  
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (field: any) => {
    field === orderBy
      ? setOrder(order === 'asc' ? 'desc' : 'asc')
      : setOrderBy(field)
  }

  const stableSort = (array: any[]) => {
    const stabilizedThis = array.map((el) => el)
    stabilizedThis.sort((a, b) => {
      let f, s
      if (orderBy === 'start_time' || orderBy === 'event_timestamp') {
        f = a[orderBy] === null || a[orderBy] === '' ? 0xffff: moment(a[orderBy], 'dddd, MMM D, YYYY LT').unix()
        s = b[orderBy] === null || b[orderBy] === '' ? 0xffff: moment(b[orderBy], 'dddd, MMM D, YYYY LT').unix()
      } else {
        f = a[orderBy]===null || a[orderBy] === '' ? 0xffff: a[orderBy]
        s = b[orderBy] === null || b[orderBy] === '' ? 0xffff: b[orderBy]
      }
      return order === 'asc' ? (f > s ? 1 : -1) : (s > f ? 1: -1)
    })
    return stabilizedThis.map((el) => el)
  }

  useEffect(() => {
    function setPageRows() {
      if (!paginate) {
        setRowsPerPage(0)
      }
    }
    
    function setTotalHeader() {
      let totalCalc = {}
      for(let column of columns) {
        totalCalc[column.field] = 0
        for (let row of rows) {
          let data = row[column.field]
          if (data[0] === '$') {
            data = data.slice(2)
          }
          totalCalc[column.field] += parseInt(data)
        }
      }
      totalCalc['engagement'] = 0
      for (let row of rows) {
        totalCalc['engagement'] += parseInt(row['engagement'])
      }
      totalCalc[Object.keys(totalCalc)[0]] = 'Totals'
      totalCalc['step_title'] = ''
      let clicks = parseInt(totalCalc['clicks'])
      let views =  parseInt(totalCalc['views'])
      let leads = parseInt(totalCalc['leads'])
      let engagement = parseInt(totalCalc['engagement'])
      let requests = parseInt(totalCalc['requests'])
      let event_count = parseInt(totalCalc['event_count'])
      let sales_revenue = parseFloat(totalCalc['sales_revenue'])

      totalCalc['ctr'] = (views ? (clicks * 100 / views).toFixed(1) : 0) + '%'
      totalCalc['conv_rate'] = (views ? (leads * 100 / views).toFixed(1) : 0) + '%'
      totalCalc['bounce_rate'] = (views ? (engagement * 100 / views).toFixed(1) : 0) + '%'
      totalCalc['cr'] = (requests ? (clicks * 100 / requests).toFixed(1) : 0) + '%'
      totalCalc['rpe'] = '$ ' + (event_count ? sales_revenue / event_count : 0).toFixed(1)

      totalCalc['sales_revenue'] = '$ ' + totalCalc['sales_revenue']
      totalCalc['form_flow'] = ''
      setComprehensiveData(totalCalc)
    }

    setPageRows()
    setTotalHeader()
  }, [paginate, rows, columns]) //eslint-disable-line

  const cols = CreateHeaders(columns)

  let tempRows = [...rows]
  if (sort) {
    tempRows = stableSort(tempRows)
  }

  return (
    <TableContainer className={className} component={Paper} >
      <Table stickyHeader={stickyHeader} >
        <TableHead className={classes.tableHeader}>
          <TableRow style={{ zIndex: 1 }}>
            {cols.map(
              (column, index) =>
                column.headerName && (
                  <TableCell
                    key={column.field}
                    ref={column.ref}
                    align={column.align}
                    width={column.width}
                    style={{ 
                      fontSize: '16px', 
                      fontWeight: 'bold',
                    }}
                  >
                    { sort ? <TableSortLabel
                      active={orderBy === column.field}
                      direction={orderBy === column.field ? order : 'asc'}
                      onClick={() => createSortHandler(column.field)}
                    >
                      {column.headerName}
                    </TableSortLabel> : column.headerName
                    }
                    {showComprehensiveData && <span>{comprehensiveData && comprehensiveData[column.field]}</span>}
                  </TableCell>
                ),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {
             (paginate ?
              tempRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : tempRows
             ).map((row, index) => (
              <MSortTableRow
                key={row.id || index}
                row={row}
                index={index}
                tableProps={props}
              />
            ))
          }

          {!rows.length && (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                align='center'
              >
                No records found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {paginate && <TableFooter>
            <TablePagination 
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={columns.length}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}/>
        </TableFooter>}
      </Table>
    </TableContainer>
  )
}

export default MSortTable
