import GA4Events from './GA4Events'
import { SnackbarProvider } from 'notistack';

const EventsPage = () => {
  return (
    <SnackbarProvider maxSnack={1}>
      <GA4Events />
    </SnackbarProvider>
  )
}

export default EventsPage