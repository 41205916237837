import React from 'react'
import { Select, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

type Props = {
  label?: string
  data:
    | Array<{
        id: any
        text: any
      }>
    | Array<string>
  previousData?: any
  input?: any
  value?: string
  size?: any
  variantType?: any
  sort?: boolean
}

const MSelect: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> =
  ({
    className,
    input,
    label,
    data,
    previousData,
    size = 'medium',
    value,
    variantType = 'outlined',
    sort = true,
  }) => {
    
    if (data!==undefined && sort) {
      data?.sort((a, b) => {
        return a.text < b.text ? -1 : 1
      })
    }

    const getItem = (item: any, index:number) => {
      for (let i = 0; previousData && i < previousData.length; i++)
        if (item.id === previousData[i]) return <></>
      if (typeof item === 'string' && item === '')
        return <></>
      if (typeof item === 'string') {
        return (
          <MenuItem key={item || index} value={item}>
            {item}
          </MenuItem>
        )
      } else {
        return (
          <MenuItem key={index} value={item.id}>
            {item.text}
          </MenuItem>
        )
      }
    }

    return (
      <FormControl
        variant={variantType?variantType:'outlined'}
        className={className}
        size={size}
      >
        <InputLabel>{label}</InputLabel>
        <Select label={label} {...input}>
          {data?.map((item, index) => getItem(item,index))}
        </Select>
      </FormControl>
    )
  }

export default MSelect
