import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Form, FormSpy, Field } from 'react-final-form'
import cookie from 'react-cookies'
import { Button, Grid } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'
import RefreshIcon from '@material-ui/icons/Refresh'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import api from 'utils/api'
import { dFilterList } from 'data/options'
import MainLayout from 'layout/MainLayout'
import MDateRangePicker from 'components/forms/MDateRangePicker'
import MSortTable from 'components/MSortTable/MSortTable'
import MSpinner from 'components/forms/MSpinner'
import MFilterList from 'components/forms/MFilterList'
import MChips from 'components/forms/MChips'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 200,
    },
    select: {
      minWidth: 110,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    filterList: {
      position: 'absolute',
      zIndex: 5,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button: {
      height: 50,
      verticalAlign: 'middle',
    },
    table: {
      maxHeight: 'calc(100vh - 200px)',
      minHeight: 'calc(100vh - 200px)',
    },
    backDrop: {
      zIndex: 11,
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
)

const columns: any[] = [
  { field: 'session_id', headerName: 'Session ID', width: '100' },
  {
    field: 'start_time',
    headerName: 'Start Time',
    align: 'center',
    width: '200',
  },
  {
    field: 'event_count',
    headerName: 'Event',
    align: 'center',
    width: '50',
  },
  {
    field: 'cpc_click_count',
    headerName: 'CPC Click',
    width: '50',
    align: 'center',
  },
  {
    field: 'lead_submit_count',
    headerName: 'Lead Submit',
    width: '50',
    align: 'center',
  },
  { field: 'source', headerName: 'Source', align: 'center', width: '160' },
  { field: 'device', headerName: 'Device', align: 'center', width: '100' },
  { field: 'location', headerName: 'Location', align: 'center', width: '200' },
  {
    field: 'first_url',
    headerName: 'First Visit URL',
    align: 'center',
    width: 'auto',
  },
]

let defaultFilter = {
  dateRange: {
    startDate: new Date(),
    endDate: new Date(),
  },
}

type Props = {
  project?: string
}
const SessionExplorerPage: React.FC<Props> = ({ project }) => {
  const classes = useStyles()
  const [state, setState] = useState<{
    loading: boolean
    rows: any[]
    filter?: any
  }>({
    loading: true,
    rows: [],
    filter: defaultFilter,
  })

  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<any[]>()

  const fetchData = async (params) => {
    setState({
      ...state,
      loading: true,
    })

    try {
      const cookieData = cookie.load('session-filter')
      let filterListData = []
      if (cookieData) {
        filterListData = cookieData
      }

      cookie.save('date-range', params.dateRange, { path: '/' })
      cookie.save('project', project, {path: '/'})
      
      const res = await api.get('/sessions', {
        ...params.dateRange,
        project: project,
        filter: filterListData,
        dateRange: undefined,
      })

      //add some fields
      for (let row of res) {
        const linkUrl = encodeURI(`session_id=${row.session_id}`)
        row.session_id =
          `<a href='/session-detail?${linkUrl}' target='_blank'>` +
          row.session_id +
          '</a>'
      }
      //const res = []
      setState({
        loading: false,
        rows: res,
        filter: params,
      })
    } catch {
      setState({
        ...state,
        loading: false,
      })
    }
  }

  const onChange = (props) => {
    fetchData(props.values)
  }

  const onFilterSubmit = () => {
    toggleFilterList()
    const cookieData = cookie.load('session-filter')
    let filterListData = []
    if (cookieData) {
      filterListData = cookieData
    }
    setFilterData(filterListData)
    fetchData(state.filter)
  }

  const onFilterChipChange = () => {
    const cookieData = cookie.load('session-filter')
    let filterListData = []
    if (cookieData) {
      filterListData = cookieData
    }
    setFilterData(filterListData)
    fetchData(state.filter)
  }

  const toggleFilterList = () => {
    setFilterOpen(!filterOpen)
  }

  useEffect(() => {
    cookie.save('session-explorer', [])
    fetchData(defaultFilter)
  }, [defaultFilter]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData(state.filter)
  },[project]) //eslint-disable-line react-hooks/exhaustive-deps

  const dateRange = cookie.load('date-range')
  defaultFilter.dateRange = dateRange || defaultFilter.dateRange

  return (
    <MainLayout title='Session Explorer Page'>
      <>
        <Form
          initialValues={defaultFilter}
          onSubmit={fetchData}
          render={({ handleSubmit, values }) => (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <FormSpy subscription={{ values: true }} onChange={onChange} />
              <Grid container alignItems='center' spacing={2}>
                <Grid item>
                  <Button
                    variant='contained'
                    color='secondary'
                    className={classes.button}
                    endIcon={<FilterList />}
                    onClick={toggleFilterList}
                  >
                    Apply Filters
                  </Button>
                  <div
                    style={
                      filterOpen ? { display: 'block' } : { display: 'none' }
                    }
                    className={classes.filterList}
                  >
                    <MFilterList
                      properties={dFilterList}
                      onSubmit={onFilterSubmit}
                    />
                  </div>
                </Grid>
                <Grid item xs={5} lg={5}>
                  <MChips
                    properties={dFilterList}
                    onChange={onFilterChipChange}
                    filterData={filterData}
                  />
                </Grid>
                <Grid item style={{ marginLeft: 'auto' }}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='secondary'
                    className={classes.button}
                    startIcon={<RefreshIcon />}
                  >
                    Refresh Data
                  </Button>
                </Grid>
                <Grid item>
                  <Field
                    label='Date Range'
                    name='dateRange'
                    render={(props) => <MDateRangePicker {...props} />}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        />
        <MSortTable
          rows={state.rows}
          columns={columns}
          className={classes.table}
          defaultSort='session_id'
          paginate={true}
        />

        <MSpinner open={state.loading} />
      </>
    </MainLayout>
  )
}

const mapStateToProps = (state) => {
  return state.project
}
export default connect(mapStateToProps)(SessionExplorerPage)
