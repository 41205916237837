import cookie from 'react-cookies'
import { Chip } from '@material-ui/core'

const conditionArray: any[] = [
  {
    id: 'contains',
    text: 'contains',
  },
  {
    id: '==',
    text: 'equals',
  },
  {
    id: 'starts with',
    text: 'starts with',
  },
  {
    id: 'ends with',
    text: 'ends with',
  },
  {
    id: '!=',
    text: 'does not equal',
  },
  {
    id: 'does not contain',
    text: 'does not contain',
  },
  {
    id: '<',
    text: 'less than',
  },
  {
    id: '<=',
    text: 'less than or equal to',
  },
  {
    id: '>',
    text: 'greater than',
  },
  {
    id: '>=',
    text: 'greater than or equal to',
  },
]

type Props = {
  properties: any[]
  onChange: any
  filterData:any[]
}
const MChips: React.FC<Props> = ({ properties, onChange, filterData }) => {
  const handleFilterDelete = (index) => {
    const temp = filterData.map((filterItem) => {
      return filterItem
    })
    temp.splice(index, 1)
    cookie.save('session-filter', temp, { path: '/' })
    onChange()
  }
  let propertyObj = {},
    conditionObj = {}
  properties.forEach((property) => {
    propertyObj[property.id] = property.text
  })
  conditionArray.forEach((condition) => {
    conditionObj[condition.id] = condition.text
  })

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: '2px',
      }}
    >
      {filterData?.map((item, index) => {
        return (
          <li key={item.id || index}>
            <Chip
              label={
                propertyObj[item.property] +
                ' ' +
                conditionObj[item.condition] +
                ' ' +
                item.value
              }
              style={{margin: '3px'}}
              onDelete={() => handleFilterDelete(index)}
            />
          </li>
        )
      })}
    </div>
  )
}

export default MChips
