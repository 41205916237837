import { useState, useEffect } from 'react'
import { Form, FormSpy, Field } from 'react-final-form'
import cookie from 'react-cookies'
import { connect } from 'react-redux'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import RefreshIcon from '@material-ui/icons/Refresh'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar } from 'notistack'

import api from 'utils/api'
import { dimensionList } from 'data/options'
import MainLayout from 'layout/MainLayout'
import MDateRangePicker from 'components/forms/MDateRangePicker'
import MAutocomplete from 'components/forms/MAutocomplete'
import MTreeTable from 'components/MTreeTable/MTreeTable'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 250,
    },
    select: {
      minWidth: 110,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    button: {
      height: 50,
      verticalAlign: 'middle',
    },
    table: {
      maxHeight: 'calc(100vh - 200px)',
      minHeight: 'calc(100vh - 200px)',
    },
    backDrop: {
      zIndex: 11,
    },
    paper: {
      marginTop: theme.spacing(1)
    }
  }),
)
const columns: any[] = [
  { field: 'dimension1', headerName: 'Source', width: 'auto' },
  { field: 'views', headerName: 'Views', align: 'center', width: 100 },
  { field: 'clicks', headerName: 'Clicks', align: 'center', width: 100 },
  { field: 'ctr', headerName: 'CTR', align: 'center', width: 100},
  {
    field: 'cpc_clicks',
    headerName: 'CPC Clicks',
    align: 'center',
    width: 100,
  },
  { field: 'leads', headerName: 'Leads', align: 'center', width: 100},
  {
    field: 'conv_rate',
    headerName: 'Conversion Rate',
    align: 'center',
    width: 100,
  },
  {
    field: 'bounce_rate',
    headerName: 'Engagement Rate',
    align: 'center',
    width: 100,
  },
]


let defaultFilter = {
  dimension1: dimensionList[0].id,
  dimension2: '',
  dimension3: '',
  device: 'All',
  dateRange: {
    startDate: new Date(),
    endDate: new Date(),
  },
}

type Props = {
  project?: string
}
const SourcesPage:React.FC<Props> = ({project}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState<{
    loading: boolean
    rows: any[]
    filter?: any
  }>({
    loading: true,
    rows: [],
    filter: defaultFilter,
  })
  
  const fetchData = async (params: any) => {
    setState({
      ...state,
      loading: true,
    })

    try {
      cookie.save('date-range', params.dateRange, { path: '/' })
      const res = await api.get('/sources', {
        ...params,
        ...params.dateRange,
        project: project,
        dimensionValues: [],
        dateRange: undefined,
      })

      //const res = rows
      // const res = sourcesMock

      if (params.dimension1 === 'ga_session_id') {
        res.forEach(row => {
          const linkUrl = encodeURI(`session_id=${row.dimension1}`)
          row.dimension1 = `<a href='/session-detail?${linkUrl}' target='_blank'>` + row.dimension1 +'</a>'
        })
      }

      // add some fields
      setState({
        loading: false,
        rows: res || [],
        filter: params,
      })
    } catch {
      setState({
        ...state,
        loading: false,
      })
    }
  }

  const fetchDetailData = async (params: any, parentIds: any[]) => {
    if (
      parentIds.length === 1 &&
      (params.dimension2 === '' || params.dimension2 === null)
    ) {
      enqueueSnackbar('Please select the dimenison')
      return false
    } else if (
      parentIds.length === 2 &&
      (params.dimension3 === '' || params.dimension3 === null)
    ) {
      enqueueSnackbar('Please select the dimenison')
      return false
    }

    setState({
      ...state,
      loading: true,
    })

    try {
      let dimensionValues = []
      let childrenRows
      if (state.rows[parentIds[0]].dimension1 === '(Unknown)')
        dimensionValues.push(null)
      else dimensionValues.push(state.rows[parentIds[0]].dimension1)
      childrenRows = state.rows[parentIds[0]]
      for (let i = 1; i < parentIds.length; i++) {
        childrenRows = childrenRows.children[parentIds[i]]
        dimensionValues.push(childrenRows.dimension1)
      }

      cookie.save('date-range', params.dateRange, { path: '/' })

      const res = await api.get('/sources', {
        ...params,
        ...params.dateRange,
        project: project,
        dimensionValues: dimensionValues,
        dateRange: undefined,
      })

      let newRows = state.rows.map((row) => {
        return row
      })
      childrenRows = newRows[parentIds[0]]
      for (let i = 1; i < parentIds.length; i++)
        childrenRows = childrenRows.children[parentIds[i]]

      if ( (dimensionValues.length === 1 && params.dimension2 === 'ga_session_id') || (dimensionValues.length === 2 && params.dimension3 === 'ga_session_id')) {
        res.forEach(row => {
          const linkUrl = encodeURI(`session_id=${row.dimension1}`)
          row.dimension1 = `<a href='/session-detail?${linkUrl}' target='_blank'>` + row.dimension1 +'</a>'
        })
      }

      childrenRows.children = res

      setState({
        loading: false,
        rows: newRows,
        filter: params,
      })
    } catch {
      setState({
        ...state,
        loading: false,
      })
    }
  }

  const onChange = (props: any) => {
    if (
      props.values.dimension2 !== state.filter.dimension2 ||
      props.values.dimension3 !== state.filter.dimension3
    )
      setState({
        ...state,
        filter: props.values,
      })
    else {
      fetchData(props.values)
    }
  }

  const onTreeExpand = (parentIds: any[]) => {
    return fetchDetailData(state.filter, parentIds)
  }

  // useEffect(() => {
  //   fetchData(defaultFilter)
  // }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData(state.filter)
  }, [project]) //eslint-disable-line react-hooks/exhaustive-deps
  
  // change header name
  columns[0].headerName =
    dimensionList.find((item) => item.id === state.filter?.dimension1)?.text ||
    ''

  const dateRange = cookie.load('date-range')
  defaultFilter.dateRange = dateRange || defaultFilter.dateRange
  
  return (
    <MainLayout title='Sources'>
      <>
        <Form
          initialValues={defaultFilter}
          onSubmit={fetchData}
          render={({ handleSubmit, values }) => (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <FormSpy subscription={{ values: true }} onChange={onChange} />
              <Grid container alignItems='center' spacing={1}>
                <Grid item>
                  <Field
                    className={classes.formControl}
                    name='dimension1'
                    label='Dimension1'
                    disableClearable={true}
                    render={(props) => (
                      <MAutocomplete {...props} data={dimensionList} />
                    )}
                  />
                </Grid>

                <Grid item>
                  <Field
                    className={classes.formControl}
                    name='dimension2'
                    label='Dimension2'
                    render={(props) => (
                      <MAutocomplete
                        {...props}
                        data={dimensionList}
                        previousData={[state.filter.dimension1]}
                      />
                    )}
                  />
                </Grid>

                <Grid item>
                  <Field
                    className={classes.formControl}
                    name='dimension3'
                    label='Dimension3'
                    render={(props) => (
                      <MAutocomplete
                        {...props}
                        data={dimensionList}
                        previousData={[
                          state.filter.dimension1,
                          state.filter.dimension2,
                        ]}
                      />
                    )}
                  />
                </Grid>

                <Grid item style={{ marginLeft: 'auto' }}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='secondary'
                    className={classes.button}
                    startIcon={<RefreshIcon />}
                  >
                    Refresh Data
                  </Button>
                </Grid>
                <Grid item>
                  <Field
                    label='Date Range'
                    name='dateRange'
                    render={(props) => <MDateRangePicker {...props} />}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        />

        <MTreeTable
          rows={state.rows}
          columns={columns}
          onExpand={onTreeExpand}
          maxDepth={3}
          tableName='dimension-table'
          className={classes.table}
          showComprehensiveData = {true}
          defaultSort={columns[0].field}
        />
        
        <Backdrop open={state.loading} className={classes.backDrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </>
    </MainLayout>
  )
}

const mapStateToProps = (state) => {
  return state.project
}
export default connect(mapStateToProps)(SourcesPage)
