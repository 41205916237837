import * as storage from 'utils/storage'
import { createTypes } from 'redux-action-creator'
export const types = createTypes(['LOGIN', 'LOGOUT'], 'auth')

export const login = (values: any) => (dispatch: any) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.LOGIN,
      payload: {
        id: 1,
        username: 'admin',
        fullName: 'Admin User',
      },
    })
    resolve(true)

    storage.setItem('auth', {
      id: 1,
      username: 'admin',
      fullName: 'Admin User',
    })

    //router.replace('/')
    window.location.replace('/')
  })

export const logout = () => (dispatch: any) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: types.LOGOUT,
      payload: null,
    })
    storage.deleteItem('auth')
    resolve(true)
  })
