import React, {useState, useEffect} from 'react'
import cookie from 'react-cookies'
import { Button, Grid, IconButton, Paper, TextField } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import MSelect from './MSelect'
import { Field, Form, FormSpy } from 'react-final-form'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: 5,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 5,
      maxHeight: 400,
      minWidth: 600,
      overflowY: 'scroll',
    },
    formControl: {
      width: 120,
    },
    propertyFormControl: {
      width: 270,
    },
    conditionFormControl: {
      width: 200,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
)

const conditionArray: any[] = [
  {
    id: 'contains',
    text: 'contains',
  },
  {
    id: '==',
    text: 'equals',
  },
  {
    id: 'starts with',
    text: 'starts with',
  },
  {
    id: 'ends with',
    text: 'ends with',
  },
  {
    id: '!=',
    text: 'does not equal',
  },
  {
    id: 'does not contain',
    text: 'does not contain',
  },
  {
    id: '<',
    text: 'less than',
  },
  {
    id: '<=',
    text: 'less than or equal to',
  },
  {
    id: '>',
    text: 'greater than',
  },
  {
    id: '>=',
    text: 'greater than or equal to',
  }
]

type Props = {
  properties: any[]
  onSubmit: any
}

const MFilterList: React.FC<Props> = ({ properties,  onSubmit }) => {
  const [state, setState] = useState<any[]>([{
    property: properties[0].id, condition: conditionArray[0].id, value:''
  }])

  const classes = useStyles()

  useEffect(() => {
    const cookieData = cookie.load('session-filter')
    if ( cookieData ) {
      setState(cookieData)
    }
  }, [])

  const onChangeState = (newState) => {
    cookie.save('session-filter', newState, { path: '/' })
    setState(newState)
  }
  const onChangeConditions = (props, index) => {
    let newState = state.map((condition) => {
      return condition
    })

    if (props.values.property) {
      newState[index].property = props.values.property
    }
    if (props.values.condition) {
      newState[index].condition = props.values.condition
    }
    onChangeState(newState)
  }

  const onTextChange = (e, index) => {
    let newState = state.map((condition) => {
      return condition
    })
    newState[index].value = e.target.value
    onChangeState(newState)
  }

  const addFilter = () => {
    const newState = state.map((condition) => {
      return condition
    })

    newState.push({
      property: properties[0].id,
      condition: conditionArray[0].id,
      value: '',
    })
    onChangeState(newState)
  }

  const removeFilter = (index) => {
    let newState = state.map((condition) => {
      return condition
    })
    newState.splice(index, 1)
    onChangeState(newState)
  }

  return (
    <Paper elevation={3} className={classes.container}>
      {state.map((row, index) => {
        return (
          <Form
            initialValues={state[index]}
            key={index}
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => (
              <form className={classes.form} onSubmit={handleSubmit} noValidate>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={(props) => onChangeConditions(props, index)}
                />
                <Grid container spacing={1}>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      size='small'
                      onClick={() => removeFilter(index)}
                    >
                      <Remove />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Field
                      className={classes.propertyFormControl}
                      name='property'
                      label='property'
                      variantType='standard'
                      render={(props) => (
                        <MSelect {...props} data={properties} label='Property' />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      className={classes.conditionFormControl}
                      name='condition'
                      label='condition'
                      variantType='standard'
                      render={(props) => (
                        <MSelect
                          {...props}
                          data={conditionArray}
                          label='Condition'
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      className={classes.formControl}
                      name='value'
                      label='Value'
                      placeholder='Filter Value'
                      autoComplete='off'
                      onChange={(e) => onTextChange(e, index)}
                      value={row.value}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          />
        )
      })}

      <Grid container>
        <Grid item>
          <Button startIcon={<Add />} color='primary' onClick={addFilter}>
            Add Filter
          </Button>
        </Grid>
        <Grid item style={{ marginLeft: 'auto' }}>
          <Button variant='contained' color='primary' onClick={onSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default MFilterList
