import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      maxWidth: '250px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  }),
)

type Props = {
  columns: TableColumnDef[]
  className?: string
  rows: Array<any>
  stickyHeader?: boolean
  handleProps?: any
}

type RowProps = {
  row: any
  tableProps: Props
}

const MTableRow: React.FC<RowProps> = ({ row, tableProps }) => {
  const classes = useStyles()
  const { columns, handleProps } = tableProps

  const [anchorEl, setAnchorEl] = useState<any>()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  
  const onMenuItemHandler = (index, rowId) => {
    handleProps[index].handler(rowId)
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <TableRow hover>
        {columns.map(
          (column) =>
            column.headerName && (
              <TableCell
                key={column.field}
                align={column.align}
                className={classes.tableCell}
              >
                {column.renderer
                        ? column.renderer(row[column.field], row)
                        : row[column.field]}
              </TableCell>
            ),
        )}
        <TableCell style={{width: 30}}>
          <IconButton onClick={handleClick}>
            <MoreVert/>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleClose()}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: '15ch',
              },
            }}
          >
            {
              handleProps.map( (handleItem, index) => {
                return <MenuItem 
                          key={handleItem.text} 
                          dense={true} 
                          onClick={() => onMenuItemHandler(index, row.id)}
                      >
                        {handleItem.text}
                      </MenuItem>
              })
            }
          </Menu>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const MSettingTable: React.FC<Props> = (props) => {
  const { rows, columns, className, stickyHeader = true } = props

  return (
    <TableContainer className={className}>
      <Table stickyHeader={stickyHeader}>
      <TableHead>
          <TableRow style={{ zIndex: 1 }}>
            {columns.map(
              (column) =>
                column.headerName && (
                  <TableCell
                    key={column.field}
                    width={column.width}
                    align={column.align}
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                  >
                    {column.headerName}
                  </TableCell>
                ),
            )}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <MTableRow
              key={row.id || index}
              row={row}
              tableProps={props}
            />
          ))}

          {!rows.length && (
            <TableRow>
              <TableCell
                colSpan={columns.length + 1}
                align='center'
              >
                No records found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MSettingTable
