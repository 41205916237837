import React, { useState, useEffect } from 'react'
import { Form, FormSpy, Field } from 'react-final-form'
import { useHistory } from 'react-router'
import RefreshIcon from '@material-ui/icons/Refresh'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Backdrop from '@material-ui/core/Backdrop'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import cookie from 'react-cookies'

import api from 'utils/api'
import MSortTable from 'components/MSortTable/MSortTable'
import MAutocomplete from 'components/forms/MAutocomplete'
import MDateRangePicker from 'components/forms/MDateRangePicker'
import MainLayout from 'layout/MainLayout'
import { eventDimensionList } from 'data/options'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 250,
    },
    select: {
      minWidth: 110,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    button: {
      height: 50,
      verticalAlign: 'middle',
    },
    table: {
      maxHeight: 'calc(100vh - 200px)',
      minHeight: 'calc(100vh - 200px)',
    },
    backDrop: {
      zIndex: 11,
    },
    paper: {
      marginTop: theme.spacing(1),
    },
  }),
)

let defaultFilter = {
  dimension1: 'event_name',
  dimension2: '',
  dimension3: '',
  dateRange: {
    startDate: new Date(),
    endDate: new Date(),
  },
}

const columns: any[] = [
  { field: 'key_name', headerName: 'Key Name', width: 200 },
  { field: 'event_timestamp', headerName: 'Event Timestamp', width: 200 },
  { field: 'event_name', headerName: 'Event Name', width: 200 },
  //{ field: 'source', headerName: 'Source', width: 150 },
  //{ field: 'ga_client_id', headerName: 'Client ID', width: 150 },
  { field: 'event_param', headerName: 'Event Param', width: 'auto'},
  { field: 'event_param_value', headerName: 'Event Param Value', width: 'auto'},
  { field: 'ga_client_id', headerName: 'Client ID', width: 150 },
  { field: 'ga_session_id', headerName: 'Session ID', width: 150 },
  { field: 'domain', headerName: 'Domain', width: 150 },
  { field: 'sale_revenue', headerName: 'Sale Revenue', width: 150 },
  { field: 'sale_type', headerName: 'Sale Type', width: 150 },
  { field: 'sale_tier', headerName: 'Sale Tier', width: 150 },
  { field: 'buyer', headerName: 'Buyer', width: 150 },
  { field: 'sub_buyer', headerName: 'Sub Buyer', width: 150 },
  { field: 'pub_name', headerName: 'Pub Name', width: 150 },
  { field: 'pub_token', headerName: 'Pub Token', width: 150 },
  { field: 'pub_cost', headerName: 'Pub Cost', width: 150 },
  // { field: 'count', headerName: 'Count', align: 'center', width: 100 },
]

const ServerEvents = () => {
  const classes = useStyles()
  const history = useHistory()
  const [viewMode, setViewMode] = useState<'dimension' | 'raw'>('dimension')

  const [state, setState] = useState<{
    loading: boolean
    rawRows: any[]
    dimensionRows: any[]
    filter?: any
  }>({
    loading: true,
    rawRows: [],
    dimensionRows: [],
    filter: defaultFilter,
  })

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: any | null,
  ) => {
    setViewMode(newAlignment)
  }

  const fetchData = async (params) => {
    setState({
      ...state,
      loading: true,
    })

    try {
      cookie.save('date-range', params.dateRange, { path: '/' })
      const res = await api.get('/server-events/rawRows', {
        ...params,
        ...params.dateRange,
        dateRange: undefined,
      })

      const res1 = await api.get('/server-events/dimensionRows', {
        ...params,
        ...params.dateRange,
        dateRange: undefined,
      })

      setState({
        loading: false,
        rawRows: res,
        dimensionRows: res1,
        filter: params,
      })
    } catch {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }))
    }
  }

  const onChange = (props) => {
    fetchData(props.values)
  }

  const onManageKeys = () => {
    history.push('/server-events/manage-keys')
  }

  useEffect(() => {
    fetchData(defaultFilter)
  }, [defaultFilter]) //eslint-disable-line

  const dateRange = cookie.load('date-range')
  defaultFilter.dateRange = dateRange || defaultFilter.dateRange

  const dimensionColumns: any[] = []

  for (let i = 1; i <= 3; i += 1) {
    const key = 'dimension' + i
    if (state.filter[key]) {
      const dimension = eventDimensionList.find(
        (item) => item.id === state.filter[key],
      )
      dimensionColumns.push({
        field: key,
        headerName: dimension.text,
      })
    }
  }

  dimensionColumns.push(
    { field: 'event_count', headerName: 'Event Count', width: '100px' },
    { field: 'sales_revenue', headerName: 'Sale Revenue', width: '100px' },
    { field: 'rpe', headerName: 'RPE', width: '100px' },
  )

  return (
    <MainLayout title='Server Events Page'>
      <>
        <Form
          initialValues={defaultFilter}
          onSubmit={fetchData}
          render={({ handleSubmit, values }) => (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <FormSpy subscription={{ values: true }} onChange={onChange} />
              <Grid container alignItems='center' spacing={1}>
                {viewMode === 'dimension' && (
                  <>
                    <Grid item>
                      <Field
                        className={classes.formControl}
                        name='dimension1'
                        label='Dimension1'
                        disableClearable={true}
                        render={(props) => (
                          <MAutocomplete {...props} data={eventDimensionList} />
                        )}
                      />
                    </Grid>

                    <Grid item>
                      <Field
                        className={classes.formControl}
                        name='dimension2'
                        label='Dimension2'
                        render={(props) => (
                          <MAutocomplete
                            {...props}
                            data={eventDimensionList}
                            previousData={[state.filter.dimension1]}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item>
                      <Field
                        className={classes.formControl}
                        name='dimension3'
                        label='Dimension3'
                        render={(props) => (
                          <MAutocomplete
                            {...props}
                            data={eventDimensionList}
                            previousData={[
                              state.filter.dimension1,
                              state.filter.dimension2,
                            ]}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item style={{ marginLeft: 'auto' }}>
                  <ToggleButtonGroup
                    exclusive
                    value={viewMode}
                    onChange={handleAlignment}
                  >
                    <ToggleButton value='dimension'>Dimensions</ToggleButton>
                    <ToggleButton value='raw'>Raw Entries</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.button}
                    startIcon={<LockOpenIcon />}
                    onClick={onManageKeys}
                  >
                    Manage Keys
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type='submit'
                    variant='contained'
                    color='secondary'
                    className={classes.button}
                    startIcon={<RefreshIcon />}
                  >
                    Refresh Data
                  </Button>
                </Grid>
                <Grid item>
                  <Field
                    label='Date Range'
                    name='dateRange'
                    render={(props) => <MDateRangePicker {...props} />}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        />

        {viewMode === 'raw' && (
          <MSortTable
            className={classes.table}
            rows={state.rawRows}
            columns={columns}
            defaultSort={'event_timestamp'}
            sort={true}
            paginate={false}
          />
        )}
        {viewMode === 'dimension' && (
          <MSortTable
            className={classes.table}
            rows={state.dimensionRows}
            columns={dimensionColumns}
            sort={true}
            paginate={false}
            showComprehensiveData={true}
          />
        )}

        <Backdrop open={state.loading} className={classes.backDrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </>
    </MainLayout>
  )
}

export default ServerEvents
