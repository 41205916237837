import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { useState, useEffect } from 'react'

type Props = {
  label?: string
  data: any[]
  previousData?: any
  input: any
  disableClearable?: boolean
}

const MAutocomplete: React.FC<
  Props & React.InputHTMLAttributes<HTMLInputElement>
> = ({ className, input, label, data, previousData, disableClearable=false }) => {
  const [listData, setListData] = useState<any[]>(data)
  const [disable, setDisable] = useState<boolean>(false)
  useEffect(() => {
    let tempData: any[] = []
    setDisable(false)
    data.forEach((item) => {
      let i = 0
      for (i = 0; previousData && i < previousData.length; i++) {
        if (previousData[i] === '' || !previousData[i]) setDisable(true)
        if (item.id === previousData[i]) break
      }

      if (previousData && i === previousData.length) tempData.push(item)
      if (!previousData) tempData.push(item)
    })

    tempData.sort((a: any, b: any) => {
      return a.text < b.text ? -1 : 1
    })

    setListData([...tempData])
  }, [data, previousData])

  const handleChange = (event: any, option: any) => {
    input.onChange(option ? option.id : option)
  }

  const getOptionSelected = (option: any, value: any) => {
    if (value === '') {
      return true
    } else {
      return option.id === value
    }
  }

  const getOptionLabel = (x: any) => {
    if (typeof x === 'string') {
      const selected = data.find((item) => item.id === x)
      return selected ? selected.text : ''
    }

    return x.text
  }

  return (
    <FormControl variant='outlined' className={className}>
      <Autocomplete
        defaultValue={input.value}
        value={input.value}
        disableClearable={disableClearable}
        onChange={handleChange}
        options={listData}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        disabled={disable ? true : false}
        renderInput={(params) => (
          <TextField {...params} label={label} variant='outlined' />
        )}
      />
    </FormControl>
  )
}

export default MAutocomplete
