import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { DateRangePicker, DateRange } from 'materialui-daterange-picker'
import moment from 'moment'

type Props = {
  label?: string
  input: any
}

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  rangePicker: {
    position: 'absolute',
    right: 0,
    top: '100%',
    zIndex: 3,
  },
})

const getDateRangePresetName = (startDate, endDate) => {
  const format = 'MM/DD/YYYY'
  const sStr = moment(startDate).format(format)
  const eStr = moment(endDate).format(format)
  
  if (moment().format(format) === sStr && sStr === eStr) {
    return 'Today'
  } else if (sStr === eStr && sStr === moment().subtract(1, 'days').format(format)) {
    return 'Yesterday'
  } else if (sStr === moment().startOf('week').format(format) 
              && eStr === moment().endOf('week').format(format)) {
    return 'This Week'
  } else if (sStr === moment().subtract(1, 'weeks').startOf('week').format(format) &&
              eStr === moment().subtract(1, 'weeks').endOf('week').format(format)) {
    return 'Last week'
  } else if (eStr === moment().format(format) &&
              sStr === moment().subtract(7, 'days').format(format)) {
    return 'Last 7 days'
  } else if (sStr === moment().startOf('month').format(format) &&
              eStr === moment().endOf('month').format(format)) {
    return 'This month'
  } else if (sStr === moment().subtract(1, 'months').startOf('month').format(format) &&
              eStr === moment().subtract(1, 'months').endOf('month').format(format)) {
    return 'Last month'
  }

  return sStr + ' - ' + eStr
}

const MSelect: React.FC<
  Props & React.InputHTMLAttributes<HTMLInputElement>
> = ({ input, label }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const togglePicker = () => setOpen(!open)
  const handleChange = (range: DateRange) => {
    input.onChange(range)
    togglePicker()
  }

  const { startDate, endDate } = input.value

  const formatted = getDateRangePresetName(startDate, endDate)

  return (
    <div className={classes.container}>
      <TextField
        label={label}
        variant='outlined'
        InputProps={{
          readOnly: true,
        }}
        value={formatted}
        inputProps={{
          style: {
            cursor: 'pointer',
          },
        }}
        onClick={togglePicker}
      />
      <DateRangePicker
        wrapperClassName={classes.rangePicker}
        open={open}
        toggle={togglePicker}
        onChange={handleChange}
      />
    </div>
  )
}

export default MSelect
